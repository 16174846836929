import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import etd_homebanner from "Assets/Images/etd_homebanner.jpg";
import { authService } from "services/authService";
import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";
import { showErrorAlertMessage, clearAlertMessage} from "components/store/actions/shared/alertActions";
import { loginPageTitle} from "helpers/localization";
import { setLoginButtonSkipLink } from "components/store/slicers/skipLinkSlice";

const useStyles = makeStyles({
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
});

export default function Login() {
  const [authInProgress, setAuthInProgress] = React.useState(
    authService.getAuthInProgress()
  );

  const history = useNavigate();
  const { t, i18n } = useTranslation("home");
  const classes = useStyles();
  const dispatch = useDispatch();
  const handlePostAuthenticate = (userInfo: any) => {
    if (userInfo !== null && (userInfo?.username || userInfo.EID)) {
      i18n.changeLanguage(userInfo.isoLanguageCode ?? "en-GB");
      if (userInfo.companyUId === "00000000-0000-0000-0000-000000000000") {
        dispatch(showErrorAlertMessage(t("msgNoCompanyAssigned")));
      } else {
        if (authService.isEhiAdmin()) {
          history("/admin/users");
        } else {
          history("/home");
        }
      }
    }
  };

  useEffect(() => {
    dispatch(setLoginButtonSkipLink())
  }, [])
  useEffect(() => {
    document.title = loginPageTitle();
    const subscription = authService.currentUser.subscribe((x) =>
      handlePostAuthenticate(x)
    );
    const authInProgressSubscription = authService.authInProgress.subscribe(
      (x) => {
        setAuthInProgress(x === "true");
      }
    );
    return () => {
      subscription.unsubscribe();
      authInProgressSubscription.unsubscribe();
    };

  }, [authInProgress]);

  return (
    <Grid container spacing={2}>
      <Backdrop className={classes.backdrop} open={authInProgress}>
        <CircularProgress />
      </Backdrop>
      <div
        style={{
          textAlign: "center",
          opacity: 0.75,
          width: "100%",
          display: authInProgress ? "" : "none",
        }}
      >
        <h2>{t("loginWaitMessage")}</h2>
      </div>
      <div style={{ textAlign: "center", width: "100%" }}>
        <Grid
          container
          style={{
            width: "100.8%",
            marginTop: "40px",
            paddingLeft: "8px",
            paddingRight: "0"
          }}
        >
          <Grid item xs={12} md={12}>
            <img
              src={etd_homebanner}
              alt="welcome to the new Enterprise Travel Direct"
              width="100%"
              height="auto"
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}
