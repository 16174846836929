import { BehaviorSubject } from "rxjs";
import jwt_decode from "jwt-decode";
import { UserRoles } from "../helpers/authTypes";
import { DriverInfo } from "components/Reservation/steps/JourneyStart/journeyInputType";
import { ReservationDriver } from "components/Reservation/steps/Reservation/reservationFormType";
import { CarClubAutoVeraDetails } from "components/Reservation/steps/Shared/journeyProfileType";
import { DriverViewModel } from "components/Reservation/steps/Reservation/reservationFormType";
const currentUserSubject = new BehaviorSubject<any>(
  JSON.parse(sessionStorage.getItem("user_info") || "{}")
);
const authInProgressSubject = new BehaviorSubject<string>(
  sessionStorage.getItem("loginInProgress") || "false"
);

export const authService = {
  authenticate,
  logout,
  isAuthenticated,
  isEhiAdmin,
  isCompanyAdmin,
  isAdmin,
  isAllowed,
  getRole,
  getUserCompanyInternalId,
  getUserCompanyUId,
  getUserLocale,
  getisoCountryCode,
  getUserName,
  getUserFirstName,
  getUserLastName,
  getUserEmail,
  getUserAppSecId,
  getUserCarClubCompanyId,
  getUserDriverId,
  setUserDriverId,
  getUserCarClubId,
  getUserccReauthExpired,
  setUserccReauthExpired,
  getUserccPersisted,
  setUserccPersisted,
  setUserccLogout,
  getUserInfo,
  getUserCompanyCountryCode,
  getUserCompanyCountry,
  setAuthInProgress,
  getAuthInProgress,
  isTokeExpired,
  getAccessTokenExpirydTime,
  getIdTokenExpirydTime,
  getDriverInfo,
  getUserPermissions,
  setDriverInfo,
  resetDriverInfo,
  getReservationDriver,
  setReservationDriver,
  resetReservationDriver,
  setCarClubAutoVeraInfo,
  getBookerDetails,
  setTakoApiEnv,
  reSetTakoApiEnv,
  getTakoApiEnv,
  getUserObject,
  currentUser: currentUserSubject.asObservable(),
  authInProgress: authInProgressSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

function setAuthInProgress(value) {
  sessionStorage.setItem("loginInProgress", value);
  authInProgressSubject.next(value);
}

function getAuthInProgress() {
  return authInProgressSubject.value === "true";
}
function getRole(): string {
  const token = sessionStorage.getItem("access_token");
  if (token !== null) {
    let decoded_token: any = jwt_decode(token);
    return decoded_token.role;
  }

  return "";
}

function isEhiAdmin(): boolean {
  const token = sessionStorage.getItem("access_token");
  if (token !== null) {
    let decoded_token: any = jwt_decode(token);
    return decoded_token.role === UserRoles.ehiAdmin;
  }

  return false;
}

function isCompanyAdmin(): boolean {
  const token = sessionStorage.getItem("access_token");
  if (token !== null) {
    let decoded_token: any = jwt_decode(token);
    return decoded_token.role === UserRoles.companyAdmin;
  }

  return false;
}

function isAdmin(): boolean {
  return isEhiAdmin() || isCompanyAdmin();
}

function isAuthenticated(): boolean {
  let token = sessionStorage.getItem("access_token");
  if (token !== null || token !== undefined) return isValidateToken(token);
  return false;
}

function isAllowed(roles: string[]): boolean {
  //grab the access token, parse thru the claims to see if the user has particular role
  const role = getRole();
  if (role !== null) {
    for (let allowedRole of roles) {
      if (role.toLowerCase() === allowedRole.toLocaleLowerCase()) return true;
    }
  }
  return false;
}
async function authenticate(token: string) {
  if (isValidateToken(token)) {
    sessionStorage.setItem("access_token", token);
    let decoded_token: any = jwt_decode(token);
    console.log("Decoded Token : ", decoded_token);
    sessionStorage.setItem("user_info", JSON.stringify(decoded_token));
    currentUserSubject.next(decoded_token);

    return true;
    // }
  } else {
    sessionStorage.clear();
    sessionStorage.clear();
  }
  return false;
}

function resetCarClubAutoVeraInfo() {
  sessionStorage.removeItem("user_carclub");
}

function setCarClubAutoVeraInfo(value: CarClubAutoVeraDetails) {
  resetCarClubAutoVeraInfo();
  sessionStorage.setItem("user_carclub", JSON.stringify(value));
}

function getCarClubAutoVeraInfo() {
  return JSON.parse(sessionStorage.getItem("user_carclub") || "{}");
}

function getUserObject() {
  return JSON.parse(sessionStorage.getItem("user_info") || "{}");
}
function getUserCompanyInternalId() {
  const user = getUserObject();
  return user.companyId;
  // return user.companyInternalNumber;
}
function getUserCompanyUId() {
  const user = getUserObject();
  return user.companyUId;
}

function getUserLocale() {

  const user = getUserObject();
  //console.log("user="+JSON.stringify(user,null,2));
  if (user.isoLanguageCode) return user.isoLanguageCode;// ?.slice(0, 2);
  else return "en-GB";
}

function getisoCountryCode() {
  const user = getUserObject();
  if (user.isoLanguageCode) {
    let codes = user.isoLanguageCode?.split("-");
    return codes.length > 1 ? codes[1] : "GB";
  }

  return "GB";
}

function getUserCompanyCountryCode() {
  const user = getUserObject();
  return user.companyCountryCode;
}

function getUserCompanyCountry() {
  const user = getUserObject();
  return user.companyCountry;
}

function getUserFirstName() {
  const user = getUserObject();
  return user.firstName;
}
function getUserLastName() {
  const user = getUserObject();
  return user.lastName;
}
function getUserEmail() {
  const user = getUserObject();
  return user.emailAddress;
}

function getUserAppSecId() {
  const user = getUserObject();
  return user.appSecUserId;
}
function getUserCarClubCompanyId() {
  const user = getUserObject();
  return user.carClubCompanyId;
}
function getUserDriverId() {
  const user = getUserObject();
  return user.carClubMemberId;
}

function getUserCarClubId() {
  const user = getUserObject();
  return `${user.carClubCompanyId}-${user.carClubMemberId}`;
}

function setUserDriverId(driverId) {
  var value = getUserObject();
  value.carClubMemberId = driverId;
  sessionStorage.setItem("user_info", JSON.stringify(value));
}

function getUserccReauthExpired() {
  const carClubInfo = getCarClubAutoVeraInfo();
  return carClubInfo?.isCCReauthExpired ?? false;
}

function setUserccReauthExpired(isccExpired) {
  const value = getCarClubAutoVeraInfo();
  value.isCCReauthExpired = isccExpired;
  sessionStorage.setItem("user_carclub", JSON.stringify(value));
}

function getUserccPersisted() {
  const carClubInfo = getCarClubAutoVeraInfo();
  return carClubInfo?.isCCPersisted ?? false;
}

function setUserccPersisted(isccPersisted) {
  var value = getCarClubAutoVeraInfo();
  value.isCCPersisted = isccPersisted;
  sessionStorage.setItem("user_carclub", JSON.stringify(value));
}

function setUserccLogout() {
  var value = getCarClubAutoVeraInfo();
  value.isCCPersisted = false;
  value.isCCReauthExpired = false;
  sessionStorage.setItem("user_carclub", JSON.stringify(value));
}

function getUserName() {
  const user = getUserObject();
  return user.username === "" || user.username === null
    ? user.EID
    : user.username;
}

function getUserPermissions() {
  const user = getUserObject();
  return user.permissions;
}

function getUserInfo() {
  return getUserObject();
}

function logout() {
  // remove user from local storage to log user out
  sessionStorage.removeItem("currentUser");
  sessionStorage.removeItem("access_token");
  sessionStorage.removeItem("user_info");
  sessionStorage.removeItem("loginInProgress");
  currentUserSubject.next(null);
}

function isValidateToken(token: string | null) {
  if (token !== null && token !== "") {
    let decodedToken: any = jwt_decode(token);

    //no or invalid roles then don't allow
    if (decodedToken.role !== null && decodedToken.role !== "") {
      let currentTime = new Date().getTime();
      let expiryTime = decodedToken.exp * 1000;
      if (expiryTime > currentTime) return true;
      else {
        alert("Invalid TOKEN");
        logout();
        return false;
      }
    }
  }
  return false;
}

function isTokeExpired(): boolean {
  let token = sessionStorage.getItem("access_token") ?? null;
  return !isValidateToken(token);
}

function getAccessTokenExpirydTime(): number {
  const token = sessionStorage.getItem("access_token");
  if (token !== null) {
    let encoded_token: any = jwt_decode(token);
    return encoded_token.exp * 1000;
  }

  return 0;
}

function getIdTokenExpirydTime(): number {
  const token = sessionStorage.getItem("idToken");
  if (token !== null) {
    let encoded_token: any = jwt_decode(token);
    return encoded_token.exp * 1000;
  }

  return 0;
}

function getDriverInfo(): DriverInfo {
  return JSON.parse(sessionStorage.getItem("driver_info") || "{}");
}

function setDriverInfo(value: DriverInfo) {
  resetDriverInfo();
  sessionStorage.setItem("driver_info", JSON.stringify(value));
}

function resetDriverInfo() {
  sessionStorage.removeItem("driver_info");
}

function getReservationDriver(): ReservationDriver {
  return JSON.parse(sessionStorage.getItem("reservation_driver") || "{}");
}

function setReservationDriver(value: ReservationDriver) {
  resetReservationDriver();
  sessionStorage.setItem("reservation_driver", JSON.stringify(value));
}

function resetReservationDriver() {
  sessionStorage.removeItem("reservation_driver");
}
function getBookerDetails(): DriverViewModel {
  const user = getUserObject();
  let booker: DriverViewModel = {
    userTitle: { selectedValue: user.title, selectedText: user.title },
    firstName: user.firstName,
    lastName: user.lastName,
    emailAddress: user.emailAddress,
    contactPhoneNumber: {
      selectedInternationalDiallingCode: user.preferredPhoneCountry,
      number: user.preferredPhoneNumber,
    },
    alternatePhoneNumber: {
      selectedInternationalDiallingCode: user.alternativePhoneCountry,
      number: user.alternativePhoneNumber,
    },
    isPrimaryDriver: true,
    savedEnterprisePlusNumber: user.enterprisePlusNumber,
    savedEmeraldClubNumber: user.emeraldClubNumber,
    isConsent: false,
  };
  return booker;
}

function reSetTakoApiEnv() {
  sessionStorage.removeItem("takoApiEnv");
}

function setTakoApiEnv(value: string) {
  reSetTakoApiEnv();
  sessionStorage.setItem("takoApiEnv", value?value:"");
}

function getTakoApiEnv() {
  return sessionStorage.getItem("takoApiEnv");
}