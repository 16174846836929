import React, { useEffect, useState } from "react";
import { useRef } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import { UserDetail } from "../usertype";
import { useNavigate } from "react-router-dom";
import {
  Box,
  FormControl,
  Backdrop,
  Button,
  Grid,
  Select,
  CircularProgress,
  TextField,
  Checkbox,
} from "@mui/material";
import axiosInstance from "../../../helpers/axiosInstance";

import * as Yup from "yup";
import {
  showSuccessAlertMessage,
  showErrorAlertMessage,
  clearAlertMessage,
} from "../../store/actions/shared/alertActions";
import { broadcastAnnouncement } from "../../store/actions/shared/announcementActions";
import { useDispatch } from "react-redux";
import { authService } from "services/authService";
import { useMsal } from "@azure/msal-react";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { InitialValue } from "../usertype";
import {
  Permissions,
  getUserPermissionsStatus,
} from "helpers/getUserPermissions";
import { setMyProfileTitleSkilLink } from "components/store/slicers/skipLinkSlice";

const useStyles = makeStyles({
  root: {
    "& .MuiFormHelperText-root": {
      color: "#DF0000",
      fontSize: 12,
      marginLeft: "15px",
    },
    "& ::placeholder": {
      fontStyle: "italic",
      color: "#000",
      opacity: 0.95,
      whiteSpace: "pre-wrap",
      "@media (max-width:330px)": {
        fontSize: "0.6rem",
      },
    },
    "& .MuiTypography-h1": {
      color: "red",
      fontSize: 14,
      marginLeft: "15px",
    },
  },
  inputRoot: {
    fontSize: 30,
  },
  labelRoot: {
    fontSize: 30,
    color: "red",
    "&$labelFocused": {
      color: "purple",
    },
  },
  labelText: {
    color: "#000",
    fontFamily: "Arial, Roboto, Segoe UI",
    fontSize: "0.80rem",
    fontWeight: "bold",
    verticalAlign: "middle",
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: 12,
    marginLeft: "15px",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
  labelFocused: {},
});
export const convertToFormikError = (inputDict, t, separator = ".") => {
  var errors = {};
  if (Object.keys(inputDict).length > 0) {
    for (const [key, value] of Object.entries(inputDict)) {
      var json = {};
      var tmp = json;

      var arr = key.split(separator);

      if (arr.length === 1) {
        errors[arr[0]] = t(value);
      } else {
        for (var i = 1, n = arr.length; i < n; i++) {
          const j = i + 1;
          if (j === n) tmp[arr[i]] = t(value);
          else tmp[arr[i]] = {};

          tmp = tmp[arr[i]];
        }
        errors[arr[0]] = json;
      }
    }
  }

  return errors;
};
export default function MyProfile() {
  const [loading, setLoading] = useState(false);
  const [userDetail, setuserDetail] = useState<UserDetail>(InitialValue);
  const [boboEnabled, setBoboEnabled] = useState<boolean>(false);
  const { instance } = useMsal();
  let history = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const classes = useStyles();
  const divRef = useRef<HTMLElement>(null);

  const setNullValuesToEmpty = (obj) =>
    JSON.parse(JSON.stringify(obj, (k, v) => (v === null ? "" : v)));
  
  const getUserDetail = async (uid) => {
    setLoading(true);
    const result = await axiosInstance.get(
      "/identity/user?uid=" + encodeURIComponent(uid)
    );
    setuserDetail(setNullValuesToEmpty(result.data));
    if (result.data?.companyName?.selectedValue) {
      getCompanyProfile(result.data.companyName.selectedValue);
    }
    setLoading(false);
  };

  const updateDriverId = async (companyId, setValue) => {
    const result = await axiosInstance.get(
      "/identity/getcarclubcompanyid/" + companyId
    );
    if (result.data !== null) {
      console.log(result.data.data);
      setValue("carClubDriver", {
        carClubCompanyId: result.data.data.toString(),
        carClubDriverId: "",
      });
    } else {
      setValue("carClubDriver", { carClubCompanyId: "", carClubDriverId: "" });
    }
  };
  const handleCancel = () => {
    history("/home");
  };
  const handleLogOut = () => {
    const logoutRequest = {
      idTokenHint: sessionStorage.idToken,
    };

    appInsights.trackEvent({
      name: "Auto logout Event",
      properties: { Component: "MyProfile" },
    });

    sessionStorage.clear();
    instance.logoutRedirect(logoutRequest).catch((err) => {
      appInsights.trackException({
        properties: {
          method: "myProfile: handleLogOut",
          Component: "Login",
        },
        exception: err,
        severityLevel: SeverityLevel.Error,
      });
    });
  };

  const getCompanyProfile = async (companyUId) => {
    await axiosInstance
      .get(`/company/getCompanyProfileByUId/${companyUId}`)
      .then((response) => {
        setBoboEnabled(response.data?.boboEnabled ?? false);
      })
      .catch((error) => {
        setBoboEnabled(false);
      });
  };

  useEffect(() => {
    document.title = t("basePageTitle") + t("Users.myprofilePageTitle");
    dispatch(setMyProfileTitleSkilLink())
    dispatch(clearAlertMessage());
    const fetchUserDetails = async () => {
      await getUserDetail(authService.getUserName());
    };

    // call the function
    fetchUserDetails()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  function validateSelectField(value) {
    // console.log("VALUE=", value);
    if (
      typeof value["selectedValue"] === "undefined" ||
      value["selectedValue"] === "-"
    )
      return false;
    else return true;
  }

  const userValidationSchema = Yup.object().shape({
    userTitle: Yup.object().test(
      "Select Field",
      t("Users.Errors.userTitleRequired"),
      (value) => validateSelectField(value)
    ),

    username: Yup.string()
      .matches(/^[a-zA-Z0-9äöüÄÖÜß@.'*_-]*$/, t("Users.Errors.invalidUsername"))
      .required(t("Users.Errors.userNameRequired").toString())
      .max(100, t("Users.Errors.userNameMaxLength"))
      .nullable(),
    email: Yup.string()
      .required(t("Users.Errors.emailRequired"))
      .email(t("Users.Errors.invalidEmail").toString())
      .max(100, t("Users.Errors.emailMaxLength"))
      .nullable(),
    firstName: Yup.string()
      .required(t("Users.Errors.firtNameRequired").toString())
      .max(20, t("Users.Errors.firstNameMaxLength"))
      .nullable(),
    lastName: Yup.string()
      .required(t("Users.Errors.lastNameRequired").toString())
      .max(30, t("Users.Errors.lastNameMaxLength"))
      .nullable(),
    lineManagerEmail: Yup.string()
      .email(t("Users.Errors.invalidEmail").toString())
      .max(100, t("Users.Errors.emailMaxLength"))
      .nullable(),

    preferredLanguage: Yup.object().test(
      "len",
      t("Users.Errors.preferredLangRequired"),
      (value) => validateSelectField(value)
    ),
    role: Yup.object()
      .test("len", t("Users.Errors.roleRequired"), (value) =>
        validateSelectField(value)
      )
      .required(),
    companyName: Yup.object().test(
      "len",
      t("Users.Errors.companyNameRequired"),
      (value) => validateSelectField(value)
    ),
    preferredPhoneNumber: Yup.object().shape({
      selectedInternationalDiallingCode: Yup.string()
        .notRequired()
        .nullable()
        .test(
          "selectedInternationalDiallingCode",
          t("Users.Errors.preferredCountryCodeRequired"),
          (value, number) => {
            if (!!value) {
              return value.length > 0 && value !== "+";
            }
            if(!!number.parent.number){
              return false;
            }
            return true;
          }
        ),
      number: Yup.string()
        .when(["selectedInternationalDiallingCode"], {
          is: (selectedInternationalDiallingCode) =>
            selectedInternationalDiallingCode &&
            selectedInternationalDiallingCode.length > 0 &&
            selectedInternationalDiallingCode !== "+",
          then: Yup.string()
            .notRequired()
            .nullable()
            .test(
              "number",
              t("Users.Errors.preferredPhoneRequired"),
              (value) => {
                let exp = /^[0-9]*$/;
                if (value) {
                  return exp.test(value);
                } else return false;
              }
            )
            .min(2, t("Users.Errors.preferPhoneMaxLength"))
            .max(17, t("Users.Errors.preferPhoneMaxLength")),
        }),
    }),
    alternativePhoneNumber: Yup.object().shape({
      selectedInternationalDiallingCode: Yup.string()
        .notRequired()
        .nullable()
        .test(
          "selectedInternationalDiallingCode",
          t("Users.Errors.alternateCountryCodeRequired"),
          (value, number) => {
            if (!!value) {
              return value.length > 0 && value !== "+";
            }
            if(!!number.parent.number){
              return false;
            }
            return true;
          }
        ),
      number: Yup.string()
      .when(["selectedInternationalDiallingCode"], {
        is: (selectedInternationalDiallingCode) =>
          selectedInternationalDiallingCode &&
          selectedInternationalDiallingCode.length > 0 &&
          selectedInternationalDiallingCode !== "+",
        then: Yup.string()
          .notRequired()
          .nullable()
          .test(
            "number",
            t("Users.Errors.alternatePhoneRequired"),
            (value) => {
              let exp = /^[0-9]*$/;
              if (value) {
                return exp.test(value);
              } else return false;
            }
          )
          .min(2, t("Users.Errors.preferPhoneMaxLength"))
          .max(17, t("Users.Errors.preferPhoneMaxLength")),
      }),
    }),
  });

  return (
    <Box id="viewUserBox" boxShadow={3} p={3} bgcolor="background.paper">
      <Formik
        initialValues={loading ? InitialValue : userDetail}
        validateOnChange={true}
        validateOnBlur={false}
        enableReinitialize={true}
        validationSchema={userValidationSchema}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          await axiosInstance
            .post("/identity/updateprofile", values)
            .then((res) => {
              setSubmitting(false);
              if (res.data.success) {
                let loginRequired = res.data.reloginRequired;
                dispatch(
                  showSuccessAlertMessage(
                    loginRequired
                      ? t("Users.updateProfileSuccess")
                      : t("Users.updateUserSuccess") + ": " + values.username
                  )
                );
                dispatch(
                  broadcastAnnouncement(
                    loginRequired
                      ? t("Users.updateProfileSuccess")
                      : t("Users.updateUserSuccess")
                  )
                );

                if (loginRequired) {
                  setTimeout(() => handleLogOut(), 5000);
                } else {
                  getUserDetail(authService.getUserName());
                  if (divRef && divRef.current) {
                    divRef.current.focus();
                  }
                }
              }
              //errors in updating profile like username  etc
              else {
                if (res.data.errors) {
                  let errors: any = [];
                  console.log("ERROR: ", res.data.errors);
                  if (Object.keys(res.data.errors).length > 0) {
                    for (const [key, value] of Object.entries(
                      res.data.errors
                      )) {
                        let errList: any = value;
                        for (const item of errList){
                          errors.push(item);
                        }
                      }
                    }

                  const translatedErrorlist = errors
                  .map(function (value) {
                    return t(value);
                  })
                  
                  dispatch(showErrorAlertMessage(translatedErrorlist));
                  dispatch(broadcastAnnouncement(translatedErrorlist));
                  appInsights.trackException({
                    exception: new Error("username"),
                    properties: { method: "onSubmit", Component: "myProfile" },
                    severityLevel: SeverityLevel.Error,
                  });

                  window.scrollTo(0, 0);
                } else {
                  dispatch(showErrorAlertMessage("unable to save the user."));
                  dispatch(broadcastAnnouncement("unable to save the user."));
                }
              }
            })
            .catch((err) => {
              setSubmitting(false);
              if (err.response && err.response.data.messages) {
                if (err.response.data.hasOwnProperty('errors') && Object.keys(err.response.data.errors).length > 0) {

                  for (const [key, value] of Object.entries(
                    err.response.data.errors
                  )) {
                    console.log(`${key}: ${value}`);
                    // errors;
                  }
                  let errors = convertToFormikError(
                    err.response.data.errors,
                    t
                  );
                  //  console.log("ERRS: ", errors);
                  setErrors(errors);
                }
                let errorlist = err.response.data.messages.filter(
                  (n) => n !== ""
                );
                const translatedErrorlist = errorlist
                  .map(function (value) {
                    return t(value);
                  })
                  .join("\r\n");
                dispatch(showErrorAlertMessage(translatedErrorlist));
                dispatch(broadcastAnnouncement(translatedErrorlist));
              } else {
                dispatch(showErrorAlertMessage("unable to save the user."));
                dispatch(broadcastAnnouncement("unable to save the user."));
              }

              appInsights.trackException({
                exception: err,
                properties: { method: "onSubmit", Component: "myProfile" },
                severityLevel: SeverityLevel.Error,
              });
            });
          window.scrollTo(0, 0);
        }}
      >
        {({
          values,
          isValid,
          dirty,
          setFieldValue,
          setFieldTouched,
          touched,
          handleChange,
          errors,
          isSubmitting,
        }) => (
          <Grid container>

            <Backdrop
              className={classes.backdrop}
              open={loading || isSubmitting}
            >
              <CircularProgress />
            </Backdrop>

            <Grid
              container
              direction="row"
              style={{ marginTop: "1em" }}
              className={classes.root}
            >
              <Form style={{ width: "100%" }}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                  >
                    <FormControl variant="outlined" fullWidth>
                      <label
                        className={classes.labelText}
                        htmlFor={"userTitle"}
                        id="lblUserTitle"
                      >
                        {t("Users.title")}
                      </label>
                      <Select
                        native
                        value={values.userTitle.selectedValue}
                        onChange={(opt) => {
                          let text = values.userTitle.items?.find(
                            (x) => x.value === opt.target.value
                          )?.text;
                          setFieldValue("userTitle.selectedText", text);
                          setFieldValue(
                            "userTitle.selectedValue",
                            opt.target.value
                          );
                          setFieldTouched("userTitle");
                        }}
                        fullWidth
                        inputProps={{
                          id: "userTitle",
                          name: "userTitle",
                          title: t("Users.userTitle"),
                          "aria-label": t("Users.userTitle"),
                        }}
                        name="userTitle"
                        id="userTitle"
                        error={errors["userTitle"] !== undefined ? true : false}
                        {...(errors.userTitle &&
                          touched.userTitle && {
                            "aria-describedby": "userTitle-helper-select",
                          })}
                      >
                        {values.userTitle.items?.map((opt: any) => (
                          <option key={opt.value} value={opt.value}>
                            {opt.text}
                          </option>
                        ))}
                      </Select>
                      {errors.userTitle && touched.userTitle ? (
                        <span
                          id="userTitle-helper-select"
                          className={classes.errorWarning}
                        >
                          {errors["userTitle"]?.toString() || ""}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                    item
                    xs={12}
                  >
                    <label 
                      className={classes.labelText}
                      htmlFor={"firstName"}
                    >
                      {t("Users.firstname")}
                    </label>
                    <Field
                      name="firstName"
                      id="firstName"
                      fullWidth
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      inputProps={{
                        "aria-label": t("Users.firstname"),
                        "aria-required": "true",
                      }}
                      value={values.firstName}
                      onChange= {(e) => {
                        setFieldValue("firstName", e.target.value);
                        setFieldTouched("firstName");
                      }}
                      component={TextField}
                      variant="outlined"
                      placeholder={t("Users.firstnamePlaceholder")}
                      {...(errors.firstName && {
                        "aria-describedby": "firstName-helper-text",
                      })}
                      error={errors.firstName !== undefined ? true : false } 
                      helperText={errors.firstName !== undefined  ? errors?.firstName : " "}
                    />
                  </Grid>
                  <Grid
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                    item
                    xs={12}
                  >
                    <label 
                      className={classes.labelText}
                      htmlFor={"lastName"}
                    >
                      {t("Users.lastname")}
                    </label>
                    <Field
                      name="lastName"
                      id="lastName"
                      fullWidth
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      inputProps={{
                        "aria-label": t("Users.lastname"),
                        "aria-required": true,
                      }}
                      value={values.lastName}
                      onChange= {(e) => {
                        setFieldValue("lastName", e.target.value);
                        setFieldTouched("lastName");
                      }}
                      component={TextField}
                      variant="outlined"
                      placeholder={t("Users.lastnamePlaceholder")}
                      {...(errors.lastName && {
                        "aria-describedby": "lastName-helper-text",
                      })}
                      error={errors.lastName !== undefined ? true : false } 
                      helperText={errors.lastName !== undefined  ? errors?.lastName : " "}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                  >
                    <label 
                      className={classes.labelText}
                      htmlFor={"email"}
                    >
                      {t("Users.emailaddress")}
                    </label>
                    <Field
                      name="email"
                      id="email"
                      fullWidth
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      inputProps={{
                        "aria-label": t("Users.emailaddress"),
                        "aria-required": true,
                      }}
                      value={values.email}
                      onChange= {(e) => {
                        setFieldValue("email", e.target.value);
                        setFieldTouched("email");
                      }}
                      component={TextField}
                      variant="outlined"
                      placeholder={t("Users.emailPlaceholder")}
                      {...(errors.email && {
                        "aria-describedby": "email-helper-text",
                      })}
                      error={errors.email !== undefined ? true : false } 
                      helperText={errors.email !== undefined  ? errors?.email : " "}
                    />
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                    >
                      <label 
                        className={classes.labelText}
                        htmlFor={"preferredPhoneNumber.number"}
                      >
                        {t("Users.preferredPhoneNumber")}
                      </label>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        marginBottom: "1em",
                      }}
                    >
                      <FormControl variant="outlined" fullWidth sx={{ height:"100%"}}>
                        <Select
                          native
                          value={
                            values.preferredPhoneNumber
                              ?.selectedInternationalDiallingCode
                          }
                          onChange={handleChange}
                          fullWidth
                          inputProps={{
                            id: "preferredPhoneNumber.selectedInternationalDiallingCode",
                            name: "preferredPhoneNumber.selectedInternationalDiallingCode",
                            title: t("Users.countryCodeForPreferredNumber"),
                            "aria-label": t(
                              "Users.countryCodeForPreferredNumber"
                            ),
                          }}
                          name="preferredPhoneNumber.selectedInternationalDiallingCode"
                          id="preferredPhoneNumber.selectedInternationalDiallingCode"
                          error={
                            errors["preferredPhoneNumber"] !== undefined &&
                            errors["preferredPhoneNumber"][
                              "selectedInternationalDiallingCode"
                            ] !== undefined
                              ? true
                              : false
                          }
                          {...(errors.preferredPhoneNumber?.[
                            "selectedInternationalDiallingCode"
                          ] && {
                            "aria-describedby":
                              "preferredPhoneNumber.selectedInternationalDiallingCode-helper-text",
                          })}
                        >
                          {" "}
                          <option key="" value="">
                            -
                          </option>
                          {values.preferredPhoneNumber?.availableInternationalDiallingCodes?.map(
                            (opt: any) => (
                              <option
                                key={opt.value}
                                value={opt.value}
                                disabled={opt.value === "+" ? true : false}
                              >
                                {opt.text}
                              </option>
                            )
                          )}
                        </Select>
                        {  errors["preferredPhoneNumber"] !== undefined &&
                            errors["preferredPhoneNumber"][
                              "selectedInternationalDiallingCode"
                            ] !== undefined ? (
                          <span
                            id="preferredPhoneNumber.selectedInternationalDiallingCodeError"
                            className={classes.errorWarning}
                          >
                            {errors["preferredPhoneNumber"][
                                        "selectedInternationalDiallingCode"
                                      ]}
                          </span>
                        ) : (
                          <span id="preferredPhoneNumber.selectedInternationalDiallingCodeError"></span>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={9}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        marginBottom: "1em",
                      }}
                    >
                      <Field
                        name="preferredPhoneNumber.number"
                        id="preferredPhoneNumber.number"
                        fullWidth
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        inputProps={{
                          "aria-label": t("Users.preferredPhoneNumber"),
                        }}
                        value={values.preferredPhoneNumber?.number}
                        onChange= {(e) => {
                          setFieldValue("preferredPhoneNumber.number", e.target.value);
                          setFieldTouched("preferredPhoneNumber.number");
                        }}
                        component={TextField}
                        variant="outlined"
                        error={
                          errors["preferredPhoneNumber"] !== undefined &&
                          errors["preferredPhoneNumber"]["number"] !== undefined 
                            ? true
                            : false
                        }
                        helperText={errors["preferredPhoneNumber"] !== undefined &&
                            errors["preferredPhoneNumber"]["number"] !== undefined  ?  
                            errors["preferredPhoneNumber"]["number"] : " "}
                        placeholder={t("Users.preferredPhonePlaceholder")}
                        {...(errors.preferredPhoneNumber?.["number"] && {
                          "aria-describedby":
                            "preferredPhoneNumber.number-helper-text",
                        })}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    style={{ paddingBottom: 0, marginBottom: "1em" }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                    >
                      <label 
                        className={classes.labelText}
                        htmlFor={"alternativePhoneNumber.number"}
                      >
                        {t("Users.alternativePhoneNumber")}
                      </label>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        marginBottom: "1em",
                      }}
                    >
                      <FormControl variant="outlined" fullWidth sx={{ height:"100%"}}>
                        <Select
                          native
                          value={
                            values.alternativePhoneNumber
                              ?.selectedInternationalDiallingCode
                          }
                          onChange={handleChange}
                          fullWidth
                          inputProps={{
                            id: "alternativePhoneNumber.selectedInternationalDiallingCode",
                            name: "alternativePhoneNumber.selectedInternationalDiallingCode",
                            title: t("Users.countryCodeForAlternateNumber"),
                            "aria-label": t(
                              "Users.countryCodeForAlternateNumber"
                            ),
                          }}
                          name="alternativePhoneNumber.selectedInternationalDiallingCode"
                          error={
                            errors["alternativePhoneNumber"] !== undefined &&
                            errors["alternativePhoneNumber"][
                              "selectedInternationalDiallingCode"
                            ] !== undefined
                              ? true
                              : false
                          }
                        >
                          <option key="" value="">
                            -
                          </option>
                          {values.alternativePhoneNumber?.availableInternationalDiallingCodes?.map(
                            (opt: any) => (
                              <option
                                key={opt.value}
                                value={opt.value}
                                disabled={opt.value === "+" ? true : false}
                              >
                                {opt.text}
                              </option>
                            )
                          )}
                        </Select>
                        {  errors["alternativePhoneNumber"] !== undefined &&
                            errors["alternativePhoneNumber"][
                              "selectedInternationalDiallingCode"
                            ] !== undefined ? (
                          <span
                            id="alternativePhoneNumber.selectedInternationalDiallingCodeError"
                            className={classes.errorWarning}
                          >
                            {errors["alternativePhoneNumber"][
                                        "selectedInternationalDiallingCode"
                                      ]}
                          </span>
                        ) : (
                          <span id="alternativePhoneNumber.selectedInternationalDiallingCodeError"></span>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={9}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        marginBottom: "1em",
                      }}
                    >
                      <Field
                        name="alternativePhoneNumber.number"
                        id="alternativePhoneNumber.number"
                        fullWidth
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        inputProps={{
                          "aria-label": t("Users.alternativePhoneNumber"),
                        }}
                        value={values.alternativePhoneNumber?.number}
                        onChange= {(e) => {
                          setFieldValue("alternativePhoneNumber.number", e.target.value);
                          setFieldTouched("alternativePhoneNumber.number");
                        }}
                        component={TextField}
                        variant="outlined"
                        placeholder={t("Users.alternatePhonePlaceholder")}
                        {...(errors.alternativePhoneNumber?.["number"] && {
                          "aria-describedby":
                            "alternativePhoneNumber.number-helper-text",
                        })}
                        error={
                          errors["alternativePhoneNumber"] !== undefined &&
                          errors["alternativePhoneNumber"]["number"] !== undefined
                            ? true
                            : false
                        }
                        helperText={errors["alternativePhoneNumber"] !== undefined &&
                        errors["alternativePhoneNumber"]["number"] !== undefined  ?  errors["alternativePhoneNumber"]["number"] : " "}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    style={{ paddingBottom: 0, marginBottom: "1em" }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                    >
                      <label 
                        style={{ fontWeight: "bold" }}
                      >
                        {t("ReservationWizard.reservationPage.labelLoyaltyNumbersIfApplicable")}
                      </label>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      style={{ paddingLeft: 36, paddingTop: 12, paddingBottom: 0, marginBottom: "1em" }}
                    >
                      <label 
                        className={classes.labelText}
                        htmlFor={"emeraldClubNumber"}
                      >
                        {t("Users.emeraldClubNumber")}
                      </label>
                      <Field
                        name="emeraldClubNumber"
                        id="emeraldClubNumber"
                        onChange={(opt) => {
                          setFieldValue(
                            "emeraldClubNumber",
                            opt.target.value.slice(0, 9).toUpperCase()
                          );
                        }}
                        fullWidth
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        inputProps={{
                          "aria-label": t("Users.emeraldClubNumber"),
                          "aria-required": true,
                        }}
                        value={values.emeraldClubNumber}
                        component={TextField}
                        variant="outlined"
                        {...(errors.emeraldClubNumber && {
                          "aria-describedby": "emeraldClubNumber-helper-text",
                        })}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      style={{ paddingLeft: 36, paddingTop: 12, paddingBottom: 0, marginBottom: "1em" }}
                    >
                      <label 
                        className={classes.labelText}
                        htmlFor={"enterprisePlusNumber"}
                      >
                        {t("Users.enterprisePlusNumber")}
                      </label>
                      <Field
                        name="enterprisePlusNumber"
                        id="enterprisePlusNumber"
                        onChange={(opt) => {
                          setFieldValue(
                            "enterprisePlusNumber",
                            opt.target.value.slice(0, 9).toUpperCase()
                          );
                        }}
                        fullWidth
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        inputProps={{
                          "aria-label": t("Users.enterprisePlusNumber"),
                          "aria-required": true,
                        }}
                        value={values.enterprisePlusNumber}
                        component={TextField}
                        variant="outlined"
                        {...(errors.enterprisePlusNumber && {
                          "aria-describedby": "enterprisePlusNumber-helper-text",
                        })}
                      />
                    </Grid>
                  </Grid>                  
                  <Grid
                    item
                    xs={12}
                    style={{ paddingBottom: 0, marginBottom: "1em" }}
                  >
                    <label 
                      className={classes.labelText}
                      htmlFor={"username"}
                    >
                      {t("Users.username")}
                    </label>
                    <Field
                      name="username"
                      id="username"
                      fullWidth
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      inputProps={{
                        "aria-label": t("Users.username"),
                        "aria-required": true,
                      }}
                      value={values.username}
                      onChange= {(e) => {
                        setFieldValue("username", e.target.value);
                        setFieldTouched("username");
                      }}
                      component={TextField}
                      variant="outlined"
                      placeholder={t("Users.usernamePlaceholder")}
                      {...(errors.username && {
                        "aria-describedby": "username-helper-text",
                      })}
                      error={errors.username !== undefined ? true : false } 
                      helperText={errors.username !== undefined  ? errors?.username : " "}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                  >
                    <FormControl variant="outlined" fullWidth>
                      <label 
                        className={classes.labelText}
                        htmlFor={"companyName.selectedValue"}
                      >
                        {t("Users.company")}
                      </label>
                      <Select
                        disabled
                        native
                        value={values.companyName?.selectedValue}
                        onChange={(opt) => {
                          let text = values.companyName?.items?.find(
                            (x) => x.value === opt.target.value
                          )?.text;
                          setFieldValue(
                            "companyName.selectedValue",
                            opt.target.value
                          );
                          setFieldValue("companyName.selectedText", text);
                          setFieldTouched("companyName");
                          updateDriverId(opt.target.value, setFieldValue);
                        }}
                        fullWidth
                        inputProps={{
                          id: "companyName.selectedValue",
                          name: "companyName.selectedValue",
                          title: t("Users.company"),
                          "aria-label": t("Users.company"),
                          "data-selectedtext": values.companyName?.selectedText,
                        }}
                        name="companyName.selectedValue"
                        error={
                          errors["companyName"] !== undefined ? true : false
                        }
                      >
                        {values.companyName?.items?.map((opt: any) => (
                          <option key={opt.value} value={opt.value}>
                            {opt.text}
                          </option>
                        ))}
                      </Select>
                      {errors.companyName && touched.companyName ? (
                        <span className={classes.errorWarning}>
                          {errors["companyName"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                  
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                  >
                    <FormControl
                      id="permissionLst"
                      variant="outlined"
                      fullWidth
                      style={{
                        flexDirection:"unset",
                        flexWrap:"wrap"
                      }}
                    >
                      <label style={{ fontWeight: "bold",width:"100%"}}>
                        {t("Users.Permissions")}
                      </label>
                      {Object.entries(Permissions)
                        .filter(([key]) => {
                          return isNaN(Number(key)) && key !== "None";
                        })
                        .map(([key]) => {
                          const labelId = key + "Label";
                          const checkboxId = key + "Checkox";
                          const selectedPermission: Permissions =
                            Permissions[key];
                          const label = "Users." + key;
                          return (((values.isBOBOToggleEnabled && boboEnabled && key==="BoboEnabled") || key!=="BoboEnabled") &&
                            <Grid key={key} item xs={12} md={3}>
                              <label id={labelId} htmlFor={checkboxId}>
                                <Field
                                  type="checkbox"
                                  name="permissions"
                                  component={Checkbox}
                                  id={checkboxId}
                                  disabled
                                  onChange={(e) => {
                                    setFieldValue("permissions", e.target.checked);
                                    setFieldTouched("permissions");
                                  }}
                                  checked={getUserPermissionsStatus(
                                    values.permissions ?? 0,
                                    selectedPermission
                                  )}
                                />
                                {t(label)}
                              </label>
                            </Grid>
                          );
                        })}
                    </FormControl>
                  </Grid>
                  
                  <Grid
                    container
                    spacing={3}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                    >
                      <label style={{ fontWeight: "bold" }} htmlFor={"carClubDriver.carClubDriverId"}>
                        {t("Users.carClubDriver")}
                      </label>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={3}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        marginBottom: "1em",
                      }}
                    >
                      <FormControl variant="outlined" fullWidth>
                        <Field
                          disabled
                          name="carClubDriver.carClubCompanyId"
                          id="carClubDriver.carClubCompanyId"
                          fullWidth
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          inputProps={{
                            "aria-label": t("Users.carClubDriver"),
                          }}
                          value={values.carClubDriver?.carClubCompanyId}
                          onChange= {(e) => {
                            setFieldValue("carClubDriver.carClubCompanyId", e.target.value);
                            setFieldTouched("carClubDriver.carClubCompanyId");
                          }}
                          component={TextField}
                          variant="outlined"
                          placeholder={t("Users.carClubCompanyIdPlaceholder")}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={9}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        marginBottom: "1em",
                      }}
                    >
                      <Field
                        disabled
                        name="carClubDriver.carClubDriverId"
                        id="carClubDriver.carClubDriverId"
                        fullWidth
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        inputProps={{ "aria-label": t("Users.carClubDriver") }}
                        value={values.carClubDriver?.carClubDriverId}
                        onChange= {(e) => {
                          setFieldValue("carClubDriver.carClubDriverId", e.target.value);
                          setFieldTouched("carClubDriver.carClubDriverId");
                        }}
                        component={TextField}
                        variant="outlined"
                        placeholder={t("Users.driverIdPlaceholder")}
                      />
                      <span className="RedWarningFont">
                        {" "}
                        {errors["carClubDriverId"]}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingBottom: 0, marginBottom: "1em" }}
                  >
                    <label style={{ fontWeight: "bold" }} htmlFor={"lineManagerEmail"}>
                      {t("Users.lineManageremail")}
                    </label>
                    <Field
                      disabled
                      name="lineManagerEmail"
                      id="lineManagerEmail"
                      fullWidth
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      inputProps={{ "aria-label": t("Users.lineManageremail") }}
                      value={values.lineManagerEmail}
                      onChange= {(e) => {
                        setFieldValue("lineManagerEmail", e.target.value);
                        setFieldTouched("lineManagerEmail");
                      }}
                      component={TextField}
                      variant="outlined"
                      placeholder={t("Users.linemanagerEmailPlaceholder")}
                      {...(errors.lineManagerEmail && {
                        "aria-describedby": "lineManagerEmail-helper-text",
                      })}
                    />
                    <span className="RedWarningFont">
                      {" "}
                      {errors["lineManageremail"]}
                    </span>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={
                        errors && errors["preferredLanguage.selectedValue"]
                      }
                    >
                      <label style={{ fontWeight: "bold" }} htmlFor={"preferredLanguage.selectedValue"}>
                        {t("Users.preferredLanguage")}{" "}
                        {errors && errors["preferredLanguage.selectedValue"]}
                      </label>
                      <Select
                        native
                        value={values.preferredLanguage?.selectedValue}
                        onChange={(opt) => {
                          setFieldValue(
                            "preferredLanguage.selectedValue",
                            opt.target.value
                          );
                          // setFieldValue("preferredLanguage.selectedText", text);
                          setFieldTouched("preferredLanguage");
                        }}
                        fullWidth
                        inputProps={{
                          id: "preferredLanguage.selectedValue",
                          name: "preferredLanguage.selectedValue",
                          title: t("Users.preferredLanguage"),
                          "aria-label": t("Users.preferredLanguage"),
                        }}
                        name="preferredLanguage"
                        id="preferredLanguage"
                        error={
                          errors["preferredLanguage"] !== undefined
                            ? true
                            : false
                        }
                        {...(errors.preferredLanguage &&
                          touched.preferredLanguage && {
                            "aria-describedby":
                              "preferredLanguage-helper-select",
                          })}
                      >
                        {values.preferredLanguage?.items?.map((opt: any) => (
                          <option key={opt.value} value={opt.value}>
                            {opt.text}
                          </option>
                        ))}
                      </Select>
                      {errors.preferredLanguage && touched.preferredLanguage ? (
                        <span
                          id="preferredLanguage-helper-select"
                          className={classes.errorWarning}
                        >
                          {errors["preferredLanguage"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                  >
                    <FormControl id="cc" variant="outlined" fullWidth>
                      <label style={{ fontWeight: "bold" }} htmlFor={"role.selectedValue"}>
                        {t("Users.role")}
                      </label>
                      <Select
                        disabled
                        native
                        value={values.role?.selectedValue}
                        onChange={(opt) => {
                          let text = values.role?.items?.find(
                            (x) => x.value === opt.target.value
                          )?.text;
                          setFieldValue("role.selectedText", text);
                          setFieldValue("role.selectedValue", opt.target.value);
                          setFieldTouched("role");
                        }}
                        fullWidth
                        inputProps={{
                          id: "role.selectedValue",
                          name: "role.selectedValue",
                          title: t("Users.role"),
                          "aria-label": t("Users.role"),
                        }}
                        name="role"
                        error={errors["role"] !== undefined ? true : false}
                      >
                        {values.role?.items?.map((opt: any) => (
                          <option key={opt.value} value={opt.value}>
                            {opt.text}
                          </option>
                        ))}
                      </Select>
                      {errors.role && touched.role ? (
                        <span className={classes.errorWarning}>
                          {errors["role"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>

                  <Grid container justifyContent="flex-end">
                    <Button
                      color="primary"
                      id="btnSubmit"
                      variant="contained"
                      disabled={!isValid || !dirty}
                      type="submit"
                      style={{ textTransform: "none" }}
                    >
                      {t("Users.btnSave")}
                    </Button>
                    <Button
                      color="primary"
                      id="btnCancel"
                      variant="outlined"
                      type="button"
                      onClick={handleCancel}
                      style={{ marginLeft: "0.5em", textTransform: "none" }}
                    >
                      {t("Users.btnCancel")}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        )}
      </Formik>
    </Box>
  );
}
