import React, { useEffect } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { ReservationProps } from "../../reservationType";
import Accordion from "@mui/material/Accordion";
import {
  drVehicleValidationSchema,
  isBrandSelected,
} from "./drVehicleValidationSchema";
import {
  ReservationLocationsViewModel,
  ReservationObject,
  resInitialValue,
  CarClass,
  BrandType,
  DriverDeclaration,
  LoyaltyProgramType,
} from "../Reservation/reservationFormType";
import EtdCarClasses from "../Reservation/controls/carClass";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Formik, Form, Field } from "formik";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import axiosInstance from "helpers/axiosInstance";
import { 
  isWithin12Months,
  isWithin28Days,
  isInUSAorCanada,
  isCreditCardExpirationDateValid
} from "helpers/creditCard";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import es from "date-fns/locale/es";
import fr from "date-fns/locale/fr";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "components/store/reducers/rootReducer";
import { updateReservation } from "components/store/actions/reservation/reservationActions";
import "react-datepicker/dist/react-datepicker.css";
import makeStyles from "@mui/styles/makeStyles";
import CompactJourneyDetailsView from "../Shared/compactJourneyDetailsView";

import {
  clearAlertMessage,
  showErrorAlertMessage,
  showInfoAlertMessage,
} from "components/store/actions/shared/alertActions";
import { useTranslation } from "react-i18next";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { authService } from "services/authService";
import { countriesToShowEmmision, useCo2Dict } from "controls/hooks/useCo2Dict";
import useReservationMode, {
  ReservationMode,
} from "helpers/useReservationMode";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  gridItem: {
    verticalAlign: "center",
    padding: "10px",
    "& .MuiTypography-body2": {
      fontWeight: "bold",
      fontSize: "0.875rem",
    },
  },
  heading: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  labelText: {
    color: "#000",
    fontFamily: "Arial, Roboto, Segoe UI",
    fontSize: "0.875rem",
    fontWeight: "bold",
    verticalAlign: "middle",
    whiteSpace: "nowrap",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: "0.75rem",
    marginLeft: "15px",
  },
  textarea: {
    resize: "both",
  },
  focused: { outline: "none" },
}));

const AccordionSummary = withStyles({
  root: {
    //  flexDirection: "column",
    backgroundColor: "#006639",
    fontWeight: "bold",
    color: "#fff",
    border: "2px solid #006639",
    "&$focused": {
      color: "#006639",
    },
    "&.Mui-expanded": {
      minHeight: "48px",
    },
    "&.Mui-focused": {
      backgroundColor: "#006639",
    },
  },

  content: {
    marginBottom: 0,
    marginTop: 0,
    padding: 0,
    minHeight: "12px",
  },
  expandIcon: {
    marginRight: 0,
    paddingTop: 0,
    color: "#fff",
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    "& ::placeholder": {
      color: "#767676",
      opacity: "1",
    },
  },
})(MuiAccordionDetails);

const DRVehicleSelectionForm: React.FC<ReservationProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [isCarSelected, setIsCarSelected] = React.useState(false);
  const [resMode] = useReservationMode();
  let existingRes = useSelector((state: AppState) => state.dailyRental);
  const [resdata, setResData] =
    React.useState<ReservationObject>(resInitialValue);
  const [carClasses, setCarClassess] = React.useState<CarClass | null>(null);

  const [selectedCarClass, setselectedCarClass] = React.useState({});
  const [locationsView, setlocationsView] =
    React.useState<ReservationLocationsViewModel>();

  const theme = useTheme();
  const isSmXs = useMediaQuery(theme.breakpoints.down("lg"));
  const locale = authService.getUserLocale();
  const [creditCardExpirationDate, setCreditCardExpirationDate] = React.useState('2000-08-31T00:00:00');
  const titleRef = React.useRef<HTMLDivElement>(null);
  registerLocale("de", de);
  registerLocale("es", es);
  registerLocale("fr", fr);
  const formRef = React.useRef<any>(null);
  let drRes = useSelector((state: AppState) => state.dailyRental.reservation);
  let driverModel = useSelector((state: AppState) => state.driverDetail.driver);
  let bookingRef = useSelector((state: AppState) => state.bookingReference);
  let loyalty = useSelector((state: AppState) => state.driverDetail.loyalty);

  let aclocmodel = useSelector(
    (state: AppState) => state.accountLocationDeatils
  );

  function getEmClubExectiveEliteLoyalty() {
    let key = Object.keys(LoyaltyProgramType).find(
      (x) => LoyaltyProgramType[x] === loyalty.selectedLoyaltyProgramValue
    );
    if (loyalty && loyalty.loyaltyId) {
      const exectiveClub = {
        ProgramId: key,
        MembershipId: loyalty.loyaltyId,
        TravelSector: "CAR",
      };
      return exectiveClub;
    }
    return null;
  }
  function ValidCreditCardPaymentConditionsForEdit(reservation){
    let valid = true;
    let cardPaymentErrors: string[] = [];
    const endDateTimeString = reservation?.endDateTimeString;
    const startDateTimeString = reservation?.startDateTimeString;
    const isWith28Days = isWithin28Days(startDateTimeString, endDateTimeString);
    const isAValidCreditCardExpirationDate = isCreditCardExpirationDateValid(endDateTimeString, creditCardExpirationDate);
    if (!isWith28Days)
    {
      cardPaymentErrors.push(t("Res.rentalExceeds28Days"));
      valid = false;
    }
    if (!isAValidCreditCardExpirationDate)
    {
      cardPaymentErrors.push(t("Res.rentalEndDateExceedsCreditCardExpiryDate"));
      valid = false;
    }
    if (!valid)
    {
      dispatch(showErrorAlertMessage(cardPaymentErrors));
    }
    return valid;
  }
  function CheckCreditCardPaymentConditions(reservation) {
    let cardPaymentErrors: string[] = [];

    const isCreditCardToggleEnabled = reservation?.isCreditCardToggleEnabled;
    if (isCreditCardToggleEnabled) {
      const endDateTimeString = reservation?.endDateTimeString;
      const startDateTimeString = reservation?.startDateTimeString;
      const brand = reservation?.brand;
      const isPayAtCounter = reservation?.reasonForHire.isPayAtCounter;
      const isCreditCard = reservation?.reasonForHire.isCreditCard;
      const startLocation = reservation?.journeyViewModel?.startLocation?.location;
      const isWith12Months = isWithin12Months(endDateTimeString);
      const isWith28Days = isWithin28Days(startDateTimeString, endDateTimeString);
      const isUSAorCanada = isInUSAorCanada(startLocation);
      const allowedBrand = "ET";
      if (brand === allowedBrand && isWith12Months && isWith28Days && !isPayAtCounter && isCreditCard && !isUSAorCanada){
      return true;
      }
    }
    return false;
  }


  const co2rate = useCo2Dict();

  const handleCarClassSelect = (car, key) => {
    let newCarClassess = JSON.parse(JSON.stringify(carClasses));
    console.log("Car: ", car);
    for (var i = 0; i < newCarClassess.carsInformation.classInfo.length; i++) {
      for (
        var j = 0;
        j < newCarClassess.carsInformation.classInfo[i].brandInfo.length;
        j++
      ) {
        if (
          newCarClassess.carsInformation.classInfo[i].brandInfo[j].uniqueKey ===
          car.car.uniqueKey
        ) {
          const selected =
            !newCarClassess.carsInformation.classInfo[i].brandInfo[j]
              .isSelected;
          newCarClassess.carsInformation.classInfo[i].brandInfo[j].isSelected =
            selected;
        } else {
          newCarClassess.carsInformation.classInfo[i].brandInfo[j].isSelected =
            false;
        }
        newCarClassess.carsInformation.classInfo[i].brandInfo[
          j
        ].selectedExtras = [];
      }
    }
    setCarClassess(newCarClassess);
    setselectedCarClass(car.car);
    setIsCarSelected(isBrandSelected(newCarClassess));
    let fullBrandName = "";

    if (newCarClassess !== null && newCarClassess.brand !== "") {
      if (newCarClassess.brand === BrandType.NATIONAL)
        fullBrandName = t("journeyMethod.ET");
      else if (newCarClassess.brand === BrandType.ENTERPRISE)
        fullBrandName = t("journeyMethod.ZL");
    }

    // formRef.current.setFieldTouched("carClass", true, true);
    if (formRef.current) {
      formRef.current.setFieldValue("fullBrandName", fullBrandName);
      formRef.current.setFieldValue("carClass", newCarClassess);
    }
  };

  const handleCarExtras = (key, extras) => {
    let newCarClassess = JSON.parse(JSON.stringify(carClasses));
    for (var i = 0; i < newCarClassess.carsInformation.classInfo.length; i++) {
      for (
        var j = 0;
        j < newCarClassess.carsInformation.classInfo[i].brandInfo.length;
        j++
      ) {
        if (
          newCarClassess.carsInformation.classInfo[i].brandInfo[j].uniqueKey ===
          key
        ) {
          newCarClassess.carsInformation.classInfo[i].brandInfo[
            j
          ].selectedExtras = extras;
        }
      }
    }
    setCarClassess(newCarClassess);
  };
  useEffect(() => {
    dispatch(clearAlertMessage());
    titleRef.current?.focus();
  
    const fetchCreditCardInfo = async () => getCreditCardInformation();
    const fetchCarClasses = async () => getCarClasses().catch(console.error);
  
    if (existingRes?.reservation) {
      const { isCreditCardToggleEnabled, isCreditCard, carClass } = existingRes.reservation;
  
      if (isCreditCardToggleEnabled && isCreditCard && resMode !== ReservationMode.Rebook) {
        fetchCreditCardInfo();
      }
  
      setResData(existingRes.reservation);
  
      if (carClass) {
        setCarClassess(carClass);
        setIsCarSelected(isBrandSelected(carClass));
      } else {
        fetchCarClasses();
      }
  
      if (existingRes.hasError && Object.keys(existingRes.errors).length > 0) {
        const errors = Object.values(existingRes.errors);
        console.log(errors);
        dispatch(showErrorAlertMessage(errors));
        dispatch(broadcastAnnouncement(errors.join(". ")));
        window.scrollTo(0, 0);
      }
    }
  }, []);

  const getCreditCardInformation = async () => {
    dispatch(clearAlertMessage());
    console.log("getting Credit Card Information");
    console.log("reservationNumberString", drRes?.reservationNumberString)
    setLoading(true);
    await axiosInstance
      .get(`/paymentstore/details/${drRes?.reservationNumberString}`)
      .then((result) => {
        console.log("Credit Card Information: ", result.data);
        let cardExpiryDate = result.data?.cardExpiryDate;
        setCreditCardExpirationDate(cardExpiryDate);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error in getting usercount");
        setLoading(false);
      });
  };
  const getCarClasses = async () => {
    dispatch(clearAlertMessage());
    if (aclocmodel.journeyProfileId !== "") {
      console.log("getting car classes");
      if (formRef.current) formRef.current.setFieldValue("carClass", null);
      setLoading(true);

      let model = {
        journeyProfileId: aclocmodel.journeyProfileId,
        accountId: aclocmodel.accountId,
        startLocation: aclocmodel.startLocation,
        endLocation: aclocmodel.endLocation,
        startDateTime: aclocmodel.startDateTime,
        endDateTime: aclocmodel.endDateTime,
        loyalty: getEmClubExectiveEliteLoyalty(),
      };

      console.log("getting carClasses request : ", model);
      await axiosInstance
        .post(`/reservation/carclasses`, model)
        .then((response) => {
          console.log("carClasses data : ", response.data);
          setLoading(false);
          if (response.data.errors && response.data.errors.length > 0) {
            dispatch(showErrorAlertMessage(response.data.errors));
            dispatch(broadcastAnnouncement(response.data.errors));
            setCarClassess(null);
            window.scrollTo(0, 0);
            if (props.handleBack) {
              props.handleBack(0, 0);
            }
          } else {
            setCarClassess(response.data);
          }
          if (response.data.warnings && response.data.warnings.length > 0) {
            dispatch(showInfoAlertMessage(response.data.warnings));
            dispatch(broadcastAnnouncement(response.data.warnings));
            window.scrollTo(0, 0);
          }
        })
        .catch((error) => {
          dispatch(showErrorAlertMessage(t("UnexpectedError")));
          dispatch(broadcastAnnouncement(t("UnexpectedError")));
          setCarClassess(null);

          console.error("There was an error!", error);
          setLoading(false);
          window.scrollTo(0, 0);
          if (props.handleBack) {
            props.handleBack(0, 0);
          }
        });
    } else {
      setCarClassess(null);
    }
  };
  const getRservationModel = () => {
 var model = {
      ...drRes,
      driverViewModel: driverModel,
      carClass: carClasses,
      additionalInformation: bookingRef,
      loyalty: loyalty,
      isBOBOBooking: driverModel.isBOBOBooking,
      driverViewModelForBOBO: driverModel.isBOBOBooking ? driverModel : null,
    };

    return model;
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={resdata}
      validateOnChange={true}
      validateOnBlur={false}
      validationSchema={drVehicleValidationSchema}
      innerRef={formRef}
      onSubmit={async (values, { setFieldTouched, setSubmitting }) => {
        var model = getRservationModel();
        console.log("submitted values (dr vehicle selectionForm): ", model);
        dispatch(clearAlertMessage());
        let url = `/dailyrental/${
          resMode === ReservationMode.Rebook ? "rebook" : "validate"
        }`;
        appInsights.trackTrace({
          message: "sending DR request for validation",
          properties: { object: values, Component: "DR Reservation" },
          severityLevel: SeverityLevel.Information,
        });
        await axiosInstance
          .post(url, model)
          .then((res) => {
            setSubmitting(false);
            if (res.data.success) 
            {
              console.log("validated.. (reservationForm)", res.data.data);
              if (existingRes?.reservation?.reservationNumber && existingRes?.reservation.isCreditCard && resMode !== ReservationMode.Rebook)
              {
                //Edit Credit Card Reservation
                let validCC = ValidCreditCardPaymentConditionsForEdit(res.data.data);
                if (props?.handleNext)
                {
                  if (validCC)
                  {
                    props.handleNext(0,0,true);
                  }
                  else
                  {
                    //New User Story will come...
                    props.handleNext(0,0,false);
                  }
                }
              }
              else
              {
                //New reservation or non credit card edit or reebook reservation...
                const creditCardStatus = CheckCreditCardPaymentConditions(res.data.data);
                if (res.data.data?.reasonForHire) {
                  const updatedReasonForHire = {
                    ...res.data.data.reasonForHire,
                    isCreditCard:creditCardStatus,
                  };
                  dispatch(
                    updateReservation({
                      reservation: {...res.data.data,isCreditCard:creditCardStatus},
                      hasError: false,
                      errors: [],
                    })
                  );
                  if (props?.handleNext) {
                    if (updatedReasonForHire.isCreditCard) {
                      props.handleNext(0,0,true);
                    }
                    else {
                      props.handleNext(0,0,false);
                    }
                  }
                }
              }
            } 
            else 
            {
              console.log("Validation Error", res.data.errors);
              let errorlist: string[] = [];
              if (Object.keys(res.data.errors).length > 0) {
                for (const [key, value] of Object.entries(res.data.errors)) {
                  if (
                    key === "unexpectedError" &&
                    Object.keys(res.data.errors).length > 1
                  )
                    // remove the exception error if there are more than 1 errors in the list
                    continue;
                  else errorlist.push(value as string);
                  //console.log(value)
                }
                dispatch(showErrorAlertMessage(errorlist));
                dispatch(broadcastAnnouncement(errorlist.join(". ")));
              }
            }
          })
          .catch((err) => {
            setSubmitting(false);
            if (err.response && err.response.data.messages) {
              if (Object.keys(err.response.data.errors).length > 0) {
                for (const [key, value] of Object.entries(
                  err.response.data.errors
                )) {
                }
                //  let errors = convertToFormikError(
                //   err.response.data.errors,
                //    t
                //  );
                //  console.log("ERRS: ", errors);
                //  setErrors(errors);
              }
              let errorlist = err.response.data.messages.filter(
                (n) => n !== ""
              );
              const translatedErrorlist = errorlist
                .map(function (value) {
                  return t(value);
                })
                .join("\r\n");
              dispatch(showErrorAlertMessage(translatedErrorlist));
              dispatch(broadcastAnnouncement(translatedErrorlist));
            } else {
              dispatch(
                showErrorAlertMessage("unable to save the journey." + err)
              );
              dispatch(
                broadcastAnnouncement("unable to save the journey." + err)
              );
            }

            appInsights.trackException({
              exception: err,
              properties: { method: "onSubmit", Component: "DR Reservation" },
              severityLevel: SeverityLevel.Error,
            });
          });
        window.scrollTo(0, 0);
      }}
    >
      {({
        values,
        isValid,
        dirty,
        setFieldValue,
        setFieldTouched,
        touched,
        handleChange,
        errors,
        isSubmitting,
      }) => (
        <Box
          boxShadow={3}
          p={3}
          bgcolor="background.paper"
          style={{ width: "100%" }}
        >
          {loading ? (
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              {t("pleaseWait")} <br />
              <CircularProgress />
            </div>
          ) : (
            <Grid container>
              <div ref={titleRef} tabIndex={-1} className={classes.focused}>
                <Typography
                  variant="h1"
                  style={{
                    marginTop: 0,
                    marginBottom: "15px",
                  }}
                >
                  {t("ReservationWizard.vehicleSelectionPageTitle")}
                </Typography>
              </div>

              <Backdrop
                className={classes.backdrop}
                open={loading || isSubmitting}
              >
                <CircularProgress />
              </Backdrop>
              <CompactJourneyDetailsView
                journeyUId={"00-00"}
                journeyDetails={props.journeyDetails}
              />
              <Grid item xs={12} style={{ marginTop: 10, color: "#AB0808" }}>
                <Form style={{ width: "100%" }}>
                  <span style={{ fontWeight: "bold", color: "#000000" }}>
                    {t("lblRequiedFields")}
                    {countriesToShowEmmision.includes(
                      drRes?.locations?.startLocation.country ?? ""
                    )
                      ? t("lblRequiedCO2Fields")
                      : ""}
                  </span>

                  <Accordion
                    style={{ width: "100%" }}
                    id="carClasses"
                    defaultExpanded
                  >
                    <h2>
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon sx={{ color: "#FFFFFF" }} />
                        }
                        aria-controls="carClassesSummary"
                        id="carClassesSummary"
                        className={classes.heading}
                        onClick={() =>
                          console.log("carClassesSummary: ", values, dirty)
                        }
                      >
                        {t(
                          "ReservationWizard.reservationPage.labelSectionVehciles"
                        )}
                      </AccordionSummary>
                    </h2>
                    <AccordionDetails>
                      <div
                        role="feed"
                        aria-label={"car classes"}
                        style={{
                          alignItems: "center",
                          verticalAlign: "middle",
                          width: "100%",
                          maxHeight: isSmXs ? "auto" : "900px",
                          overflowY: "scroll",
                          textAlign: "center",
                        }}
                      >
                        {carClasses?.errors?.map((err, i) => (
                          <span>{err}</span>
                        ))}
                        {carClasses?.carsInformation?.classInfo?.map(
                          (car, i) => (
                            <div
                              role="article"
                              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                              tabIndex={0}
                              key={i}
                              style={{ marginTop: "1em" }}
                            >
                              <Field
                                component={EtdCarClasses}
                                name={"vehicles" + i}
                                carInfo={car}
                                handleCarSelect={handleCarClassSelect}
                                handleCarExtras={handleCarExtras}
                                carKey={car.uniqueKey}
                                mainIndex={i}
                                showEmission={countriesToShowEmmision.includes(
                                  drRes?.locations?.startLocation.country ?? ""
                                )}
                                locale={locale}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "1em",
                    }}
                  >
                    <Button
                      id="btnBack"
                      color="primary"
                      aria-label={t("lblBackToPreviousStep")}
                      variant="contained"
                      onClick={() => {
                        dispatch(clearAlertMessage());
                        if (props.handleBack) props.handleBack(0, 0);
                      }}
                    >
                      {t("btnBack")}
                    </Button>
                    <Button
                      id="btnSubmit"
                      color="primary"
                      variant="contained"
                      aria-label={t("lblContinueToNextStep")}
                      disabled={!isCarSelected}
                      type="submit"
                    >
                      {t("btnContinue")}
                    </Button>
                  </div>
                </Form>
              </Grid>
            </Grid>
          )}
        </Box>
      )}
    </Formik>
  );
};

export default DRVehicleSelectionForm;
