import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { AccountCircle, ExitToApp } from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import EnterpriseLogo from "Assets/Images/20_BRP_RGB_HZ.png";
import isAdminLogo from "Assets/Images/AdminTool1.png";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import { Box, Paper } from "@mui/material";
import { isTablet, isMobile } from "react-device-detect";
import { appInsights } from "../../helpers/appInsights";
import clsx from "clsx";
import Draggable from "react-draggable";
import { red } from "@mui/material/colors";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { jwtTimeoutDialogThresholdInMinutes } from "services/ssoAuthConfig";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { ssoLoginRequest, protectedResources } from "services/ssoAuthConfig";
import { authService } from "services/authService";
import { useSelector } from "react-redux";
import { AppState } from "components/store/reducers/rootReducer";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { isIE } from "react-device-detect";
import { useDispatch } from "react-redux";
import { clearAlertMessage } from "components/store/actions/shared/alertActions";
import {loginLabel}  from "helpers/localization";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: "1em",
    "& .MuiTypography-body1": {
      "@media (max-width:480px)": {
        fontSize: "0.4rem",
      },
    },
  },
  listButton: {
    "& .MuiListItem-button": {
      marginLeft: "5px",
      marginRight: "5px",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontFamily: "Arial",
    fontSize: "2em",
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  logo: {
    Height: "4em",
    margin: 4,
  },
  skipToContentLink: {
    background: "#006639",
    color: "#fff",
    border: "1px solid #fff",
    height: "30px",
    left: "50%",
    padding: "8px",
    position: "absolute",
    transform: "translateY(-100%)",
    transition: "transform 0.3s",
    "&:focus": {
      transform: "translateY(5%)",
    },
  },
  skipMain: {
    left: "-999px",
    position: "absolute",
    top: "auto",
    width: "1px",
    height: "1px",
    overflow: "hidden",
    zIindex: -999,
  },
  customHoverFocus: {
    "&.MuiIconButton-root": {
      outline: "none !important",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#17ED4B",
      color: " #00472A",
      outline: "none !important",
    },
    "&:hover": {
      backgroundColor: "#17ED4B",
      color: " #00472A",
      outline: "none !important",
    },
  },
  dialog: {
    borderRadius: 0,
  },
  button: {
    borderRadius: 0,
    textTransform: "none",
    padding: 5,
  },
  logout: {
    color: "#fff",
    backgroundColor: "#dd0000",
    "&:hover": {
      backgroundColor: "#dd0000",
    },
  },
  countdown: {
    color: red[700],
  },
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
  },
  logoRemoveStlyes: {
    background: "none",
	color: "inherit",
	border: "none",
	padding: 0,
	font: "inherit",
	cursor: "pointer",
	outline: "inherit",
  }
}));

const LogoutButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(red[700]),
  backgroundColor: red[700],
  "&:hover": {
    backgroundColor: red[700],
  },
}));

const isUserAuthenticated = authService.isAuthenticated();

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
const Header = () => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [username, setUsername] = React.useState<any>(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [isEhiAdmin, setIsEhiAdmin] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const { instance } = useMsal();
  const history = useNavigate();
  const open = Boolean(anchorEl);
  const openHome = Boolean(anchorE2);
  const linkAttribute = useSelector((state: AppState) => state.skipLink.linkAttribute)

  const dispatch = useDispatch();

  const { t } = useTranslation();

  // EnterpriseAdministrator;
  const { companyInfo } = useSelector((state: AppState) => state.companyInfo);

  const companyLogo = companyInfo?.companyLogo;

  const hasCompanyLogo = companyLogo ? true : false;
  const isccPersisted = authService.getUserccPersisted();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHomeMenu = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleHomeClose = () => {
    dispatch(clearAlertMessage());
    setAnchorE2(null);
  };

  const handleNavigation = (nav) => {
    history(nav);
  };

  const handleLogOut = () => {
    if (!sessionStorage.idToken) {
      return;
    }

    const logoutRequest = {
      idTokenHint: sessionStorage.idToken,
      prompt: "login",
    };
    if (!isccPersisted) {
      localStorage.removeItem("ccLoggedIn");
    }

    sessionStorage.clear();
    appInsights.trackEvent({
      name: "Logout action",
      properties: {
        Component: "Header",
        userId: authService.getUserName(),
        companyId: authService.getUserCompanyInternalId(),
      },
    });
    instance.logoutRedirect(logoutRequest).catch((err) => {
      appInsights.trackException({
        properties: {
          method: "Header: handleLogOut",
          Component: "Login",
        },
        exception: err,
        severityLevel: SeverityLevel.Error,
      });
    });
  };

  const handleLogin = () => {
    authService.setAuthInProgress("true");
    appInsights.trackEvent({
      name: "Login action",
      properties: { Component: "Header" },
    });

    instance
      .loginRedirect(ssoLoginRequest)
      .then((res) => {
        console.log("Authenticated: ", res);
      })
      .catch((err) => {
        console.log("Error in logging in: ", err);
        appInsights.trackException({
          properties: {
            method: "Header: handleLogin",
            Component: "Login",
          },
          exception: err,
          severityLevel: SeverityLevel.Error,
        });
        handleLogOut();
      });
  };

  const handlePostAuthenticate = (userInfo: any) => {
    if (userInfo === null) handleLogOut();
    else {
      setUsername(userInfo);
      setIsAuthenticated(authService.isAuthenticated());
      setIsAdmin(authService.isAdmin());
      setIsEhiAdmin(authService.isEhiAdmin());
    }
  };

  const [currentDate, setCurrentDate] = React.useState(new Date());

  React.useEffect(() => {
    const subscription = authService.currentUser.subscribe((x) =>
      handlePostAuthenticate(x)
    );
    return () => {
      subscription.unsubscribe();
    };
  }, [username]);

  const [sessionTimeoutDialog, setSessionTimeoutDialog] = React.useState(false);

  React.useEffect(() => {
    var timer = setInterval(() => setCurrentDate(new Date()), 20000);

    var accessToken = sessionStorage.getItem("access_token");
    if (
      accessToken &&
      authService.getAccessTokenExpirydTime().valueOf() -
        currentDate.valueOf() >
        0
    ) {
      if (
        (authService.getAccessTokenExpirydTime().valueOf() -
          currentDate.valueOf()) /
          1000 /
          60 <
        jwtTimeoutDialogThresholdInMinutes
      ) {
        if (!sessionTimeoutDialog && sessionStorage.getItem("access_token")) {
          setSessionTimeoutDialog(true);
        }
      }
    }

    if (
      (authService.getAccessTokenExpirydTime().valueOf() -
        currentDate.valueOf()) /
        1000 /
        60 <
      0
    ) {
      if (sessionTimeoutDialog) {
        setSessionTimeoutDialog(false);
        handleLogOut();
      }
    }

    return function cleanup() {
      clearInterval(timer);
    };
  });

  const handleContinueSession = () => {
    //Token Renewal: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md

    //authService.setAuthInProgress("true");

    appInsights.trackEvent({
      name: "token-refresh action",
      properties: {
        Component: "Header",
        userId: authService.getUserName(),
        companyId: authService.getUserCompanyInternalId(),
      },
    });

    var currentAccount = instance.getAllAccounts();

    const silentRequest = {
      scopes: protectedResources.apiHello.scopes,
      account: JSON.parse(sessionStorage.getItem("account") ?? "{}"),
      forceRefresh: false,
    };
    console.log("acquiring silent token: ", silentRequest.account);
    if (currentAccount && silentRequest) {
      instance
        .acquireTokenSilent(silentRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          let idToken = accessTokenResponse.idToken;
          sessionStorage.removeItem("idToken");
          sessionStorage.removeItem("access_token");
          sessionStorage.setItem("access_token", accessToken);
          sessionStorage.setItem("idToken", idToken);
          setSessionTimeoutDialog(false);
        })
        .catch((error) => {
          appInsights.trackException({
            id: "Header: handleContinueSession",
            exception: error,
            severityLevel: SeverityLevel.Error,
          });
          console.log("errors for =" + JSON.stringify(error, null, 2));
        });
    }

    setSessionTimeoutDialog(false);
  };

  const handleClickOpenSessionTimeoutDailogClose = () => {
    setSessionTimeoutDialog(false);
  };



  return (
    <div className={classes.root}>
      <Link
        to={linkAttribute}
        className={classes.skipToContentLink}
      >
        {t("SkipToMainContent")}
      </Link>
      <div role="banner">
        <Typography variant="body1">{}</Typography>
        <Grid
          container
          direction="row"
          style={{ display: "flex" }}
          justifyContent="space-between"
        >
          <Grid item xs={12} sm={12} md={11} lg={11}>
            <Grid container>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                {isMobile || isTablet ? (
                  ""
                ) : (
                  <button onClick={() => {handleNavigation("/")}} className={classes.logoRemoveStlyes}>
                    <img
                      src={EnterpriseLogo}
                      style={{ height: "3.5em", margin: 5 }}
                      alt={t("logoImageAlt")}
                    />
                  </button>
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={3}>
                {isEhiAdmin ? (
                  isMobile || isTablet ? (
                    ""
                  ) : (
                    <img
                      src={isAdminLogo}
                      style={{ height: "3.5em" }}
                      alt="AdminTool"
                    />
                  )
                ) : hasCompanyLogo && isAuthenticated ? (
                  isMobile || isTablet ? (
                    ""
                  ) : (
                    <Typography
                      style={{
                        fontFamily: "Arial",
                        fontSize: "20px",
                        marginTop: 20,
                        marginLeft: 0,
                        marginRight: 0,
                      }}
                    >
                      {t("inPartnershipWithText")}
                    </Typography>
                  )
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                {isEhiAdmin ? (
                  ""
                ) : hasCompanyLogo && isAuthenticated ? (
                  isMobile || isTablet ? (
                    ""
                  ) : (
                    <img
                      alt={t("YourCompanyLogo")}
                      style={{
                        maxHeight: "35px",
                        marginLeft: 0,
                        marginTop: 10,
                      }}
                      src={`data:image/png;base64,${companyLogo}`}
                    />
                  )
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={1} lg={1}></Grid>
        </Grid>
      </div>

      <AppBar id="AppBarId" position="static">
        <nav role="navigation">
          <Toolbar>
            <AuthenticatedTemplate>
              <IconButton
                id="homeMenu"
                edge="start"
                className={classes.customHoverFocus}
                color="inherit"
                aria-label={t("MainMenu")}
                aria-expanded={openHome ? true : false}
                // aria-controls="fade-menu"
                aria-haspopup="menu"
                disableRipple={true}
                onClick={handleHomeMenu}
                size="large"
              >
                <MenuIcon />
              </IconButton>

              <Menu
                color="inherit"
                id="fade-menu"
                anchorEl={anchorE2}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                // keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={openHome}
                className={classes.listButton}
                onClose={handleHomeClose}
              >
                {isAuthenticated && !isEhiAdmin && (
                  <MenuItem
                    id="home"
                    aria-label={t("Home")}
                    value={t("Home")}
                    onClick={() => {
                      handleNavigation("/");
                      handleHomeClose();
                    }}
                  >
                    {t("Home")}
                  </MenuItem>
                )}
                {isAuthenticated && !isEhiAdmin && (
                  <MenuItem
                    id="myJourney"
                    aria-label={t("MyJourneys")}
                    value={t("MyJourneys")}
                    onClick={() => {
                      handleNavigation("/Journey");
                      handleHomeClose();
                    }}
                  >
                    <span aria-label={t("MyJourneys")}>{t("MyJourneys")}</span>
                  </MenuItem>
                )}
                {isAdmin && (
                  <MenuItem
                    id="manageUser"
                    aria-label={t("ManageUsers")}
                    value={t("ManageUsers")}
                    onClick={() => {
                      handleNavigation("/admin/users");
                      handleHomeClose();
                    }}
                  >
                    {t("ManageUsers")}
                  </MenuItem>
                )}
                {isEhiAdmin && (
                  <MenuItem
                    id="manageConpany"
                    aria-label={t("ManageCompanies")}
                    value={t("ManageCompanies")}
                    onClick={() => {
                      handleNavigation("/admin/companies");
                      handleHomeClose();
                    }}
                  >
                    {t("ManageCompanies")}
                  </MenuItem>
                )}

                {(isMobile || isTablet) && !isEhiAdmin ? (
                  <Box>
                    <hr></hr>

                    <MenuItem
                      id="myProfileMobile"
                      value={t("MyProfile")}
                      onClick={() => {
                        if (companyInfo?.isCCProfileManagementActivated) {
                          handleNavigation("/admin/user/allprofile");
                        } else {
                          handleNavigation("/admin/user/myprofile");
                        }
                        handleClose();
                      }}
                    >
                      {t("MyProfile")}
                    </MenuItem>
                    <MenuItem
                      id="logoutMobile"
                      value={t("Logout")}
                      onClick={handleLogOut}
                    >
                      {t("Logout")}
                    </MenuItem>
                  </Box>
                ) : (
                  ""
                )}
              </Menu>
            </AuthenticatedTemplate>
            <Grid item className={classes.title}></Grid>

            {isMobile || isTablet ? (
              ""
            ) : (
              <Grid item>
                <AuthenticatedTemplate>
                  <>
                    <IconButton
                      id="btnUsername"
                      aria-label={
                        t("CurrentUserMenu") + ":" + authService.getUserName()
                      }
                      aria-expanded={open ? true : false}
                      // aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleMenu}
                      color="inherit"
                      disableRipple={true}
                      className={classes.customHoverFocus}
                      size="large"
                    >
                      <AccountCircle />
                      <Typography variant="body1">
                        &nbsp;{authService.getUserName()}
                      </Typography>
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      className={classes.listButton}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      // keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        style={{ display: isEhiAdmin ? "none" : "" }}
                        id="myProfile"
                        value={t("MyProfile")}
                        onClick={() => {
                          if (companyInfo?.isCCProfileManagementActivated) {
                            console.log("push all");
                            handleNavigation("/admin/user/allprofile");
                          } else {
                            console.log("push single");
                            handleNavigation("/admin/user/myprofile");
                          }
                          handleClose();
                        }}
                      >
                        {t("MyProfile")}
                      </MenuItem>
                      <MenuItem
                        id="logout"
                        value={t("Logout")}
                        onClick={handleLogOut}
                      >
                        {t("Logout")}
                      </MenuItem>
                    </Menu>
                  </>
                </AuthenticatedTemplate>
              </Grid>
            )}
            <Grid item>
              <UnauthenticatedTemplate>
                <Button
                  id="btnLogin"
                  data-testid="btnLogin-test"
                  variant="contained"
                  style={{
                    color: "#fff",
                    fontWeight: "bold",
                    backgroundColor: "#E91B0C",
                    border: "2px solid #fff",
                  }}
                  onClick={handleLogin}
                >
                  <ExitToApp />{" "}
                  {isUserAuthenticated ? t("login") : loginLabel()}
                </Button>
              </UnauthenticatedTemplate>
            </Grid>
          </Toolbar>
        </nav>
      </AppBar>
      <Dialog
        id="app-session-timeout"
        // style={{minWidth:"550px",maxWidth:"550px"}}
        open={sessionTimeoutDialog}
        onClose={handleClickOpenSessionTimeoutDailogClose}
        disableAutoFocus={false}
        disableEnforceFocus={false}
        role="dialog"
        PaperComponent={PaperComponent}
        aria-labelledby="session-timeout-dialog-title-id"
        aria-describedby="session-timeout-dialog-content-id"
        style={{ backgroundColor: isIE ? "white" : "transparent" }}
      >
        <DialogTitle id="session-timeout-dialog-title-id">
          <Typography>{t("jwtSessionTimeout")}</Typography>
        </DialogTitle>
        <DialogContent id="session-timeout-dialog-content-id">
          <Grid container direction="row" justifyContent="flex-start">
            <Grid item xs={12}>
              <Typography
                variant="body2"
                aria-live="assertive"
                //aria-live ="polite"
                aria-atomic="true"
                aria-relevant="additions"
              >
                {authService.getAccessTokenExpirydTime().valueOf() -
                  currentDate.valueOf() <
                0
                  ? t("jwtSessionTimeoutDialogCloseByLogout")
                  : (authService.getAccessTokenExpirydTime().valueOf() -
                      currentDate.valueOf()) /
                      60000 <
                    60
                  ? t("jwtSessionTimeoutNotes00", {
                      mintues: Math.floor(
                        (authService.getAccessTokenExpirydTime().valueOf() -
                          currentDate.valueOf()) /
                          60000
                      ),
                      seconds: (
                        Math.floor(
                          (authService.getAccessTokenExpirydTime().valueOf() -
                            currentDate.valueOf()) %
                            60000
                        ) / 1000
                      ).toFixed(0),
                    })
                  : t("jwtSessionTimeoutDialogCloseByContine")}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            id="continue-session-button-id"
            onClick={handleContinueSession}
            color="primary"
            variant="contained"
          >
            {t("continueSession")}
          </Button>
          <LogoutButton
            id="logout-button-id"
            onClick={handleLogOut}
            variant="contained"
          >
            {t("logoutNow")}
          </LogoutButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Header;
