import React, { useEffect, useState } from "react";
import { updateReservation } from "components/store/actions/reservation/reservationActions";
import {
  Box,
  Grid,
  Typography,
  Button,
  Backdrop,
  CircularProgress
} from "@mui/material";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { AppState } from "components/store/reducers/rootReducer";
import { ReservationProps } from "../../reservationType";
import { registerLocale } from "react-datepicker";
import axiosInstance from "helpers/axiosInstance";
import de from "date-fns/locale/de";
import es from "date-fns/locale/es";
import fr from "date-fns/locale/fr";
import { useDispatch, useSelector } from "react-redux";
import {  ManualCardStartActivity, ManualCardStartActivityInitialState } from "./paymentType";
import "react-datepicker/dist/react-datepicker.css";
import makeStyles from "@mui/styles/makeStyles";
import CompactJourneyDetailsView from "../Shared/compactJourneyDetailsView";

import {
  clearAlertMessage,
  showErrorAlertMessage    
} from "components/store/actions/shared/alertActions";


import { useTranslation } from "react-i18next";

import { authService } from "services/authService";
import _ from "lodash";
import configParams from "configParams";
import { getApiOrigin } from "helpers/history";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  gridItem: {
    verticalAlign: "center",
    padding: "10px",
    "& .MuiTypography-body2": {
      fontWeight: "bold",
      fontSize: "0.875rem",
    },
  },
  heading: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  labelText: {
    color: "#000",
    fontFamily: "Arial, Roboto, Segoe UI",
    fontSize: "0.875rem",
    fontWeight: "bold",
    verticalAlign: "middle",
    whiteSpace: "nowrap",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: "0.75rem",
    marginLeft: "15px",
  },
  textarea: {
    resize: "both",
  },
  focused: { outline: "none" },
}));

const PaymentForm: React.FC<ReservationProps> = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [showIframe, setShowIframe] = React.useState(false);
  const apiOrigin = getApiOrigin();
  let { reservation } = useSelector((state: AppState) => state.dailyRental);
  
  const [paymentContext, setPaymentContext] = useState<ManualCardStartActivity>(ManualCardStartActivityInitialState);    
  
 const user = authService.getUserObject();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const titleRef = React.useRef<HTMLDivElement>(null);
  registerLocale("de", de);
  registerLocale("es", es);
  registerLocale("fr", fr);
  

  const handler = async event => {
    console.log("Message Received:", event.origin, event.data)
    
    if(event.origin !== apiOrigin) return;
    let data = JSON.parse(event.data.toString());
    console.log("Message Data:", data);
    console.log("AcitivityUrn: ", paymentContext?.activityUrn);
    await finalizePayment(data.success);
 
}

  useEffect(() => {
    console.log("Passed Reservation from Redux: ", reservation);
    dispatch(clearAlertMessage());
    const getMcbpsFrame = async () => {
      await getMcbpsFrameData();
    }
    getMcbpsFrame();

   
  }, []);
  
  useEffect(() => {
    console.log("Payment Context Changed: ", paymentContext);
    window.addEventListener("message", handler)
    
    // clean up
    return () => window.removeEventListener("message", handler)
  }, [paymentContext]);

  const getMcbpsFrameData = async () => {
    setLoading(true);
    const model = { FirstName : user.firstName, 
      LastName : user.lastName, 
      Email : user.emailAddress, 
      PhoneNumber : "+44123456789", 
      Branch : reservation?.locations?.startLocation.peopleSoftId, 
      Locale : user.isoLanguageCode
    };

    await axiosInstance
      .post("/paymentstore/startactivity", model)
      .then((res) => {
        console.log("Payment Frame Data: ", res.data);
        if (res.data.success) 
        {
         setPaymentContext(res.data);
         setShowIframe(true);
        }
        else 
        {
          console.log("Validation Error", res.data.errors);
          setShowIframe(false);
          let errorlist: string[] = [];
          if (Object.keys(res.data.errors).length > 0) {
            for (const [key, value] of Object.entries(res.data.errors)) {
              if (
                key === "unexpectedError" &&
                Object.keys(res.data.errors).length > 1
              )
                // remove the exception error if there are more than 1 errors in the list
                continue;
              else errorlist.push(value as string);
            }
            dispatch(showErrorAlertMessage(errorlist));
            dispatch(broadcastAnnouncement(errorlist.join(". ")));
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error in getting usercount");
        setLoading(false);
      });
  };

  const finalizePayment = async (status:string, sessionId?:string) => {
     let model =   {state : status, activityUrn : paymentContext?.activityUrn, sessionId : sessionId };
     setLoading(true);
     console.log("Finalize Payment Data: ", model);
    await axiosInstance
      .post("/paymentstore/finalizeactivity", model)
      .then((res) => {
        console.log("Finalize Payment Data: ", res.data);      
        let newReservation = _.cloneDeep(reservation);
        newReservation?.additionalInformation?.additionalInformationFields?.forEach((item:any) => {
          if(item.fieldName.toLowerCase() === "etd_res_id") {
            item.fieldValue = paymentContext.contextId;
            }           
        });
        console.log("Updated Reservation: ", newReservation);
        if(newReservation) {
        dispatch(updateReservation({
          reservation: newReservation,
          hasError: false,
          errors: [],
        }));
      }
        setShowIframe(false);
        setLoading(false);  
        })
      .catch((err) => {
        console.log("Error in getting usercount:", err);
        setLoading(false);
      });
  };
  return (
    <Box
      boxShadow={3}
      p={3}
      bgcolor="background.paper"
      style={{ width: "100%" }}
    >
      <Grid container>
        <div ref={titleRef} tabIndex={-1} className={classes.focused}>
          <Typography
            id = "paymentPageTitle"
            variant="h1"
            style={{
              marginTop: 0,
              marginBottom: "15px",
            }}
          >
            {t("ReservationWizard.paymentPageTitle")}
          </Typography>
        </div>
        <CompactJourneyDetailsView
          journeyUId={"00-00"}
          journeyDetails={props.journeyDetails}
        />
      </Grid>
      
      <Backdrop
                className={classes.backdrop}
                open={loading }
              >
                <CircularProgress />
              </Backdrop>

        <div style={{marginTop:10}}>
        <span id="lblRequiedFields" style={{ fontWeight: "bold", color: "#000000" }}>
          {t("lblRequiedFields")}
        </span>
        </div>
      <div id="lblSecureConnection" style={{textAlign:"center", marginTop:20}}> 
        {t("lblSecureConnection")}
      </div>
      <div style={{textAlign:"center", marginTop:10}}>
      {showIframe && paymentContext.url && <iframe id="marketpayframe" src={paymentContext.url} title="Test" 
        width={500} height={600}     
       style = {{border:"0px"}}
       style-src-elem = "'unsafe-inline' http://localhost:3004"
        sandbox='allow-popups allow-scripts allow-same-origin allow-forms'></iframe> 
        }
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "1em",
        }}
      >
        <Button
          id="btnBack"
          color="primary"
          aria-label={t("lblBackToPreviousStep")}
          variant="contained"
          onClick={() => {
            dispatch(clearAlertMessage());
            if (props.handleBack) props.handleBack(0, 0);
          }}
        >
          {t("btnBack")}
        </Button>
        <Button
          id="btnSubmit"
          color="primary"
          variant="contained"
          aria-label={t("lblContinueToNextStep")}
          onClick={() => {
            dispatch(clearAlertMessage());
            if (props.handleNext) props.handleNext(0, 0);
          }}
        >
          {t("btnContinue")}
        </Button>
      </div>
      
      

     
    </Box>
  );
};
export default PaymentForm;

