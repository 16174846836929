import React from "react";
import FocusError from "../../../controls/formik/FocusOnError";
import makeStyles from '@mui/styles/makeStyles';
import { Formik, Form } from "formik";

import { useTranslation } from "react-i18next";
import {
  DropDownList,
  CompanyAdditionalInfoViewModel,
  ReservationAdditionalInformationFieldViewModel,
} from "./companiesType";
import Paper from "@mui/material/Paper";
import "../../../views/general.css";

import {
  Box,
  FormControl,
  Backdrop,
  Button,
  Grid,
  Typography,
  Select,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import axiosInstance from "../../../helpers/axiosInstance";
import {
  showSuccessAlertMessage,
  showErrorAlertMessage,
  clearAlertMessage,
} from "../../store/actions/shared/alertActions";
import { broadcastAnnouncement } from "../../store/actions/shared/announcementActions";
import { useDispatch } from "react-redux";
import { Checkbox } from "@mui/material";

var InitialValue = {
  reservationReasonForHire: {
    accountName: "",
    displayName: "",
    accountNumber: "",

    availableReasonsForHire: [
      {
        disabled: true,
        group: {
          disabled: true,
          name: "",
        },
        selected: true,
        text: "",
        value: "",
      },
    ] as DropDownList[],
    isSelectedAccountValid: false,
    renderTime: "",
    reasonForHireLabel: "",
    brands: "",
    isPayAtCounter: false,
  },
  additionalInformation: null,
  selectedAdditionalInformation: "",
  selectedReasonForHire: "",
  companyUId: "",
} as unknown as CompanyAdditionalInfoViewModel;

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "##fff",
    color: theme.palette.common.black,
    fontWeight: "bold",
    height: "60px",
    padding: "12px",
  },
  body: {
    fontSize: 12,
    padding: "8px",
  },
  footer: {
    textAlign: "right",
    justifyContent: "right",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {},
}))(TableRow);

const useStyles = makeStyles({
  root: {
    "& .hidden": {
      display: "none",
      alignItems: "center",
      flexWrap: "wrap",
    },
  },
  table: {
    minWidth: 700,
    "& .MuiTableCell-root": {
      borderLeft: "1px solid rgba(224,224,224,1)",
    },
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    alignItems: "right",
    padding: "0px",
  },
  selectRoot: {
    height: 10,
    display: "table",
  },
  select: {
    height: 6,
    //paddingTop: 0,
    //  paddingBottom:0,
    display: "table-cell",
    verticalAlign: "middle",
  },
  formControl: {
    /*  margin: 1,*/
    minWidth: 60,
  },
  selectEmpty: {
    /*  marginTop: 2,*/
  },
  buttonSize: {
    textAlign: "center",
    padding: 3,
    margin: 0,
    alignItems: "center",
  },

  button: { textTransform: "none" },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
});

const AdditionaFields = (props) => {
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const titleRef = React.useRef<HTMLDivElement>(null);
  const companyNameRef = React.useRef<HTMLInputElement>(null);
  const [additionalInfoViewModel, setCompanyAdditionalInfoViewModel] =
    React.useState<CompanyAdditionalInfoViewModel>(InitialValue);

  const [reasonForHireSelected, setReasonForHireSelected] = React.useState("");
  const [totalCount, setTotalCount] = React.useState(0);
  const [checked, setChecked] = React.useState(true);
  const [checkboxTouched, setCheckboxTouched] = React.useState(false);

  const [selectedAdditionalField, setSelectedAdditionalField] =
    React.useState("");

  const [records, setRecords] = React.useState<
    ReservationAdditionalInformationFieldViewModel[] | []
  >([]);
  const [hiddenTableDiv, setHiddenTableDiv] = React.useState("hidden");

  React.useEffect(() => {
    dispatch(clearAlertMessage());
    loadCompanyAccountNumber(props.companyUId);
  }, [props]);

  const loadCompanyAccountNumber = async (companyUId) => {
    setHiddenTableDiv("hidden");
    setLoading(true);
    if (companyUId !== "") {
      await axiosInstance
        .get(`/company/reasonforhire/${companyUId}`)
        .then((response) => {
          setCompanyAdditionalInfoViewModel(response.data ? response.data : []);
          dispatch(broadcastAnnouncement(t("Companies.ReasonForHireLoaded")));
        })
        .catch((error) => {
          setLoading(false);
          if (
            error.response != null &&
            error.response.status != null &&
            error.response.status === 400
          ) {
            dispatch(showErrorAlertMessage(t("UnexpectedError")));
            dispatch(broadcastAnnouncement(t("UnexpectedError")));
            console.error("There is an error", error);
          } else console.error("There is an error", error);
        });
    }

    setLoading(false);
  };
  const handleCheckBoxChange = async (event, selectedValue, setFieldValue) => {
    dispatch(clearAlertMessage());
    dispatch(broadcastAnnouncement(t(" ")));

    setCheckboxTouched(true);
    setChecked(event.target.checked);

    if (event.target.checked === false) {
      setSelectedAdditionalField(" ");
    } else {
      setSelectedAdditionalField(selectedValue);
    }
  };

  const handleSelectionChange = async (event, data, setFieldValue) => {
    dispatch(clearAlertMessage());
    setReasonForHireSelected(event.target.value);
    if (event.target.value === "" || event.target.value == null) {
      setTimeout(() => {
        setHiddenTableDiv("hidden");
      }, 100);
    }
    setHiddenTableDiv(" ");

    var accountId = event.target.value;
    setLoading(true);
    if (accountId !== "") {
      await axiosInstance
        .get(`/company/accounts/additionalfield/${accountId}`)
        .then((response) => {
          setChecked(true);
          let tempData = response.data.data.additionalInformation.additionalInformationFields;
          let tempDataLength = tempData as ReservationAdditionalInformationFieldViewModel[];

          setRecords(response.data ? tempData : []);
          setTotalCount(tempDataLength.length);
          setSelectedAdditionalField(
            response.data.data.selectedAdditionalInformation
          );

          dispatch(broadcastAnnouncement(t("Companies.AdditionalFieldLoaded")));
        })
        .catch((error) => {
          setLoading(false);
          if (
            error.response != null &&
            error.response.status != null &&
            error.response.status === 400
          ) {
            dispatch(showErrorAlertMessage(t("UnexpectedError")));
            dispatch(broadcastAnnouncement(t("UnexpectedError")));
            console.error("There is an error", error);
          } else console.error("There is an error", error);
        });
    }
    setLoading(false);

    setCheckboxTouched(false);
  };

  return (
    <Box id="additionalFieldsBox">
      <Formik
        initialValues={additionalInfoViewModel}
        validateOnChange={true}
        validateOnBlur={false}
        enableReinitialize={true}
        onSubmit={async (values, { setFieldTouched, setSubmitting }) => {
          dispatch(clearAlertMessage());

          values.selectedAdditionalInformation = selectedAdditionalField;
          values.selectedReasonForHire = reasonForHireSelected;
          await axiosInstance
            .post("/company/accounts/updateaccountadditionalfields", values)
            .then((response) => {
              setSubmitting(true);
              if (response.data.success) {
                dispatch(
                  showSuccessAlertMessage(
                    t("Companies.UpdateCompanyAdditionalFieldSuccess")
                  )
                );

                dispatch(
                  broadcastAnnouncement(
                    t("Companies.UpdateCompanyAdditionalFieldSuccess")
                  )
                );

                setTimeout(() => {
                  if (titleRef.current !== null) titleRef.current.focus();
                }, 1000);
                window.scrollTo(0, 0);

                setCheckboxTouched(false);
              }
            })
            .catch((err) => {
              setSubmitting(false);
              if (err.response && err.response.data.messages) {
                const errorlist = err.response.data.messages.filter(
                  (n) => n !== ""
                );
                dispatch(showErrorAlertMessage(errorlist));
                dispatch(broadcastAnnouncement(errorlist.join(",")));
                if (err.response.data.messages.indexOf("company name")) {
                  if (companyNameRef.current !== null)
                    companyNameRef.current.focus();
                }
              } else {
                dispatch(
                  showErrorAlertMessage(
                    t("Companies.UpdateCompanyAdditionalFieldFailed")
                  )
                );
                dispatch(
                  broadcastAnnouncement(
                    t("Companies.UpdateCompanyAdditionalFieldFailed")
                  )
                );
              }
              if (titleRef.current !== null) titleRef.current.focus();
            });
        }}>
        {({
          values,
          isValid,
          dirty,
          setFieldValue,
          setFieldTouched,
          touched,

          errors,
          isSubmitting,
        }) => (
          <Grid container>
            <Backdrop
              className={classes.backdrop}
              open={isSubmitting || loading}>
              <CircularProgress />
            </Backdrop>

            <Grid item xs={12} md={6}>
              <div ref={titleRef} tabIndex={-1} className="visuallyHidden">
                {t("Companies.CompanyAdditionalField")}
              </div>
              <Typography variant="h1">
                {t("Companies.CompanyAdditionalField")}
              </Typography>
            </Grid>

            <Grid
              container
              direction="row"
              style={{ marginTop: "1em" }}
              className={classes.root}>
              <Form style={{ width: "100%" }}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}>
                    <FormControl variant="outlined" fullWidth>
                      <Typography style={{ fontWeight: "bold" }}>
                        {t("Companies.AccountName")}
                      </Typography>

                      <Select
                        native
                        fullWidth
                        aria-label={t("AvailableReasonsForHire")}
                        placeholder={t("AvailableReasonsForHire")}
                        name="AvailableReasonsForHire"
                        id="AvailableReasonsForHire"
                        inputProps={{
                          "data-testid": "AvailableReasonsForHire",
                          id: "AvailableReasonsForHire",
                          name: "AvailableReasonsForHire",
                          title: t("AvailableReasonsForHire"),
                          "aria-label": t("AvailableReasonsForHire"),
                        }}
                        value={reasonForHireSelected}
                        onChange={(e) =>
                          handleSelectionChange(e, values, setFieldValue)
                        }
                        role="listbox">
                        {additionalInfoViewModel["reservationReasonForHire"][
                          "availableReasonsForHire"
                        ].map((item, index) => (
                          <option data-testid="options" key={index} value={item.value}>
                            {item.text}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid
                    className={hiddenTableDiv}
                    container
                    item
                    xs={12}
                    md={12}>
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left">
                              {t("Companies.FieldName")}{" "}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {t("Companies.FieldDescription")}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              {t("Companies.EnableCustomValidation")}
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody data-testid="BookingReferences">
                          {totalCount === 0 ? (
                            <StyledTableRow tabIndex={0}>
                              <TableCell
                                colSpan={7}
                                style={{
                                  textAlign: "center",
                                  justifyContent: "center",
                                  marginTop: "2em",
                                }}>
                                {t("Companies.NoDataMessage")}
                              </TableCell>
                            </StyledTableRow>
                          ) : (
                            (
                              records as ReservationAdditionalInformationFieldViewModel[]
                            )?.map(
                              (
                                row: ReservationAdditionalInformationFieldViewModel,
                                index: number
                              ) => (
                                
                                <StyledTableRow
                                  role='tablerow'
                                  key={index}
                                  style={{ height: "30px" }}>
                                  <StyledTableCell>
                                    {row.fieldName}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {row.helperText}
                                  </StyledTableCell>

                                  <StyledTableCell
                                    style={{
                                      textAlign: "center",
                                    }}>
                                    <Box
                                      role='box'
                                      id={"ActionButtons_" + index}
                                      flexDirection="row"
                                      alignItems="center">
                                      <Checkbox
                                        disabled
                                        name={row.fieldName.toString()}
                                        checked={
                                          selectedAdditionalField ===
                                            row.additionalInformationGid &&
                                          checked === true
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleCheckBoxChange(
                                            e,
                                            row.additionalInformationGid,
                                            setFieldValue
                                          )
                                        }
                                        inputProps={{
                                          "aria-label":
                                            row.fieldName.toString(),
                                          name: "CustomValidateCheckbox",
                                          id: "CustomValidateCheckbox" + index,
                                          value:
                                            row.additionalInformationGid.toString(),
                                        }}
                                      />
                                    </Box>
                                  </StyledTableCell>
                                </StyledTableRow>
                              )
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid className={hiddenTableDiv} container justifyContent="flex-end">
                    <Button
                      id="btnUpdate"
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={!checkboxTouched}
                      style={{ marginTop: "0.5em", textTransform: "none" }}>
                      {t("Companies.btnSave")}
                    </Button>
                  </Grid>
                </Grid>
                <FocusError />
              </Form>
            </Grid>
          </Grid>
        )}
      </Formik>
    </Box>
  );
};

export default AdditionaFields;
