import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
  Formik,
  Form,
  Field,
  FieldArray,
  FormikErrors,
  FormikTouched,
} from "formik";
import { useTranslation } from "react-i18next";
import {
  accountType,
  companyAccountType,
  messageType,
  accountErrorType,
} from "./companiesType";
import {
  Box,
  FormControl,
  Backdrop,
  Button,
  Grid,
  Typography,
  Select,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  IconButton,
  Checkbox,
  TextField,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import axiosInstance from "../../../helpers/axiosInstance";
import * as Yup from "yup";
import {
  showSuccessAlertMessage,
  showErrorAlertMessage,
  showInfoAlertMessage,
  clearAlertMessage,
} from "../../store/actions/shared/alertActions";
import { broadcastAnnouncement } from "../../store/actions/shared/announcementActions";
import { useDispatch } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import { v4 as uuidv4 } from "uuid";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
const arrayOfCountriesForCreditCard = ['france', 'germany', 'spain', 'republic of ireland', 'united kingdom']

const useStyles = makeStyles({
  root: {
    "& ::placeholder": {
      fontStyle: "italic",
      color: "#000",
      opacity: 0.95, 
      whiteSpace: "pre-wrap",
      "@media (max-width:330px)": {
        fontSize: "0.6rem",
      },
    },
    "& .MuiFormHelperText-root": {
      color: "#DF0000",
      fontSize: 12,
      marginLeft: "15px",
    },
  },
  outlinedButton: {
    border: "1px solid rgba(0, 102, 57, 0.95);",
  },
  inputRoot: {
    fontSize: 10,
  },
  labelRoot: {
    fontSize: 30,
    color: "red",
    "&$labelFocused": {
      color: "purple",
    },
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: 12,
    marginLeft: "15px",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
  table: {
    minWidth: 750,
    "& .MuiTableCell-root": {
      borderLeft: "1px solid rgba(224,224,224,1)",
      padding: "8px",
      paddingLeft: "4px",
      paaddingRight: "4px",
    },
  },
  labelFocused: {},
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "##fff",
    color: theme.palette.common.black,
    fontWeight: "bold",
    height: "60px",
    padding: "12px",
  },
  body: {
    fontSize: 12,
    padding: "4px",
  },
  footer: {
    textAlign: "right",
    justifyContent: "right",
  },
}))(TableCell);

const InitialValue: companyAccountType = {
  companyUId: "",
  companyId: 0,
  companyName: "",
  accounts: [],
};

export default function Accounts(props) {
  const [loading, setLoading] = React.useState(false);
  const [
    companyAccounts,
    setcompanyAccounts,
  ] = React.useState<companyAccountType>(InitialValue);
  const [accountTypes, setaccountTypes] = React.useState<
    { text: string; value: string }[]
  >([]);
  const [newAccountAdded, setnewAccountAdded] = React.useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { companyUid, country, isCreditCardToggleEnabled } = props;
  const addAccountButtonRef = React.useRef<HTMLButtonElement>(null);
  const titleRef = React.useRef<HTMLDivElement>(null);
  const [initialDisabledState, setInitialDisabledState] = React.useState<boolean[]>([]);

  appInsights.trackEvent({
    name: "Company Accounts", properties: { companyUId: companyUid, country: country }
  });

  const [accountError, setaccountError] = React.useState<accountErrorType[]>(
    []
  );

  function isCountryInCreditCardList(countryName) {
    const result = arrayOfCountriesForCreditCard.some(countryInList => countryName.toLowerCase() === countryInList) 
    return result;
  }

  React.useEffect(() => {
    setLoading(true);
    GetAccountTypes();
    GetCompanyAccounts(companyUid);
  }, [companyUid]);

   React.useEffect(()=>{
    console.log("useeffect change", accountError);
   },[accountError])


   React.useEffect(() => {
    const initialState = companyAccounts.accounts.map(account =>
      isCreditCardEnabled(account.billingNumber, account.isCreditCard)
    );
    setInitialDisabledState(initialState);
  }, [companyAccounts]);

  React.useEffect(() => {
    if (accountError && accountError.length > 0) {
      var newlyAddedAccount = document.getElementById(
        `accounts.${accountError.length - 1}.typeUId`
      );
      newlyAddedAccount?.focus();
    }
  }, [newAccountAdded]);

  const newAccountRow: accountType = {
    companyAccountUId: uuidv4(),
    companyUId: companyUid,
    companyAccountId: 0,
    companyId: companyAccounts.companyId,
    typeUId: "00000000-0000-0000-0000-000000000000",
    typeId: 0,
    type: "",
    nameReasonForHire: "",
    accountNumber: "",
    billingNumber: "",
    displayName: "",
    productAgnostic: false,
    isCreditCard: false,
    isNewRow: true,
  };

  const GetAccountTypes = async () => {
    await axiosInstance
      .get(`/company/accounttypes`)
      .then((response) => {
        setaccountTypes(response.data);
      })
      .catch((error) => {
        if (
          error.response != null &&
          error.response.status != null &&
          error.response.status === 400
        ) {
            dispatch(showErrorAlertMessage(t("UnexpectedError")));
            dispatch(broadcastAnnouncement(t("UnexpectedError")));
          console.error("There is an error", error);
        } else console.error("There is an error", error);

        appInsights.trackException({id: "Get Company Account Types", exception: error, severityLevel: SeverityLevel.Error});
      });
  };
  const GetCompanyAccounts = async (companyUId) => {
    if (companyUId !== null && companyUId !== "") {
      await axiosInstance
        .get(`/company/accounts/${companyUId}`)
        .then((response) => {
          console.log("companyaccounts",response)
          var result = response.data.data;
          const warningMessage = response.data.warnings
          if(warningMessage && isCreditCardToggleEnabled){
            dispatch(
              showInfoAlertMessage(warningMessage)
            );
          }
          result.accounts.forEach((account) => {
            if (account.billingNumber === null) account.billingNumber = "";
            if (account.displayName === null) account.displayName = "";
          });
          setcompanyAccounts(result);
          const x = result.accounts;
          const errorArray: accountErrorType[] = [];
          for (var i = 0; i < x.length; i++) {
            errorArray.push({
              companyAccountUId: x[i].companyAccountUId,
              isAccounterror: false,
              isBillingNumbererror: false,
              isDisplayNameerror: false,
            });
          }
          setaccountError(errorArray);
        })
        .catch((error) => {
          setLoading(false);
          if (
            error.response != null &&
            error.response.status != null &&
            error.response.status === 400
          ) {
            dispatch(showErrorAlertMessage(t("UnexpectedError")));
            dispatch(broadcastAnnouncement(t("UnexpectedError")));
            console.error("There is an error", error);
          } else console.error("There is an error", error);
          appInsights.trackException({id: "Get CompanyAccounts", exception: error, severityLevel: SeverityLevel.Error});
        });
    } else {
      setcompanyAccounts(InitialValue);
    }
    setLoading(false);
  };

  const validationErrors = (
    messageList: messageType[],
    accountlist: accountType[]
  ) => {
    var errorlist: string[] = [];      
    let accountErrorArray = accountError; 
    messageList.forEach((element: messageType) => {
      var index = accountlist.findIndex(
        (i) => i.companyAccountUId === element.code
      );
      if (accountErrorArray) {
        var errIndex = accountErrorArray
          .map((i) => i.companyAccountUId)
          .indexOf(element.code);
        if (errIndex !== -1) {
          if (element.message.toLowerCase().indexOf("billing") !== -1) {
            accountErrorArray[errIndex].isBillingNumbererror = true;
           document.getElementById(`accounts.${index}.billingNumber`)?.focus();
          }
          if (element.message.toLowerCase().indexOf("account") !== -1) {
            accountErrorArray[errIndex].isAccounterror = true;            
            document.getElementById(`accounts.${index}.billingNumber`)?.focus();
            document.getElementById(`accounts.${index}.accountNumber`)?.focus();
          }
          if (element.message.toLowerCase().indexOf("displayname") !== -1) {
            accountErrorArray[errIndex].isDisplayNameerror = true;
            document.getElementById(`accounts.${index}.displayName`)?.focus();
          }
        }
      }
      setaccountError(accountErrorArray);
      var errormessage = t("Companies." + element.message, {
        AccountNumber: accountlist[index].accountNumber,
        BillingNumber: accountlist[index].billingNumber,
      });
      errorlist.push(errormessage);
    });
    const errlist = errorlist.filter(
      (item, index) => errorlist.indexOf(item) === index
    );
    dispatch(showErrorAlertMessage(errlist));
    if (titleRef.current !== null) titleRef.current.focus();
    //Uncomment to Focus First error
    // if(messageList.length>1)
    // {
    //   var index = accountlist.findIndex(
    //     (i) => i.companyAccountUId === messageList[0].code
    //   );
    //   if (messageList[0].message.toLowerCase().indexOf("billing") !== -1) {
    //     document.getElementById(`accounts.${index}.billingNumber`)?.focus();
    //   }
    //   if (messageList[0].message.toLowerCase().indexOf("account") !== -1) {
    //     document.getElementById(`accounts.${index}.accountNumber`)?.focus();
    //   }
    //   if (messageList[0].message.toLowerCase().indexOf("displayname") !== -1) {
    //     document.getElementById(`accounts.${index}.displayName`)?.focus();
    //   }
    // }
   
  };

  const getAccountValidationErrorMessage = (errors: any) => {
      return errors?.length > 0 ? errors[errors.length-1]?.accountNumber : "";
  }
  const getBillingValidationErrorMessage = (errors: any) => {
    return errors?.length > 0 ? errors[errors.length-1]?.billingNumber : "";
}


  const accountValidationSchema = Yup.object().shape({
    accounts: Yup.array().of(
      Yup.object().shape({
        typeUId: Yup.string()
          .notOneOf(["00000000-0000-0000-0000-000000000000"])
          .required(t("Companies.typeRequiredError")),
        displayName: Yup.string()
          .notRequired()
          .nullable()
          .max(150, t("Companies.displayNameLengthError")),
        accountNumber: Yup.string()
          .required(t("Companies.accountNumberRequiredError"))
          .nullable()
          .max(16, t("Companies.accountNumberLengthError"))
          .matches(/^[a-zA-Z0-9]*$/, t("Companies.accountNumberFormatError")),
        billingNumber: Yup.string()
          .notRequired()
          .nullable()
          .max(24, t("Companies.billingNumberLengthError"))
          .matches(/^[a-zA-Z0-9]*$/, t("Companies.billingNumberFormatError")),
        isCreditCard: Yup.boolean()
        .when('billingNumber', {
          is: val => val == null,
          then: Yup.boolean().oneOf([false], t("Companies.individualCreditCardError"))
        })
      })
    ), 
  });
 
  const isCreditCardEnabled = (billingNumber, isCreditCard) => {
    if(isCreditCardToggleEnabled &&  billingNumber !== "" && isCreditCard ===true){
      return true;
    } 
    else{
      return false;
    }
  }
  

  return (
    <Box id="accountsBox">
      <Formik
        initialValues={loading ? InitialValue : companyAccounts}
        validateOnChange={true}
        validateOnBlur={false}
        enableReinitialize={true}
        validationSchema={accountValidationSchema}
        onSubmit={async (values, { setFieldTouched, setSubmitting }) => {
          dispatch(clearAlertMessage());
          let accountErrorArray = accountError; 
          accountErrorArray.forEach((element) => {
            element.isAccounterror = false;
            element.isBillingNumbererror = false;
            element.isDisplayNameerror = false;
          });
          setaccountError(accountErrorArray);
            await axiosInstance
            .post("/company/updateaccounts", values)
            .then(async (response) => {              
              setSubmitting(false);
              setLoading(true);
              GetCompanyAccounts(companyUid);  
              if (response.data.success) {
                appInsights.trackEvent({
                  name: "Update Company Accounts", properties: { companyUId : companyUid }
                });
                dispatch(
                  showSuccessAlertMessage(
                    t("Companies.updateCompanyAccountsSuccess")
                  )
                );
                dispatch(
                  broadcastAnnouncement(
                    t("Companies.updateCompanyAccountsSuccess")
                  )
                );
                if (titleRef.current !== null) titleRef.current.focus();
                
              }
            })
            .catch((err) => {
              setSubmitting(false);
              console.log(err.response.data);
              if (err?.response?.data?.messages) {
                setTimeout(() => {
                  validationErrors(
                    err.response.data.messages,
                    values.accounts || []
                  );
                }, 500);
              } else {
                dispatch(
                  showErrorAlertMessage("Unable to update the accounts.")
                  );
                  dispatch(
                      broadcastAnnouncement("Unable to update the accounts.")
                  );
              }
              appInsights.trackException({id: "Update CompanyAccounts", exception: err, severityLevel: SeverityLevel.Error});
            });
            window.scrollTo(0, 0);
        }}
      >
        {({
          values,
          isValid,
          dirty,
          setFieldValue,
          setFieldTouched,
          touched,
          handleChange,
          errors,
          isSubmitting,
        }) => (
          <Grid container>
            <Backdrop
              className={classes.backdrop}
              open={isSubmitting || loading}
            >
              <CircularProgress />
            </Backdrop>
            <Grid item xs={12} md={6}>
              <div ref={titleRef} tabIndex={-1} className="visuallyHidden">
                {t("Companies.companyAccountPageTitle")}
              </div>
              <Typography data-testid="CompanyAccounts" variant="h1">
                {t("Companies.companyAccountPageTitle")}
              </Typography>
            </Grid>
            <Form style={{ width: "100%" }}>
              <Grid
                container
                direction="row"
                style={{ marginTop: "1em" }}
                className={classes.root}
              >
                <TableContainer component={Paper}>
                  <FieldArray
                    name="accounts"
                    render={(arrayHelpers) => (
                      <>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="left">
                                {t("Companies.type")}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {t("Companies.nameOrReasonForHire")}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {t("Companies.friendlyAccountName")}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {t("Companies.AccountName")}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {t("Companies.billingNumber")}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {t("Companies.action")}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {t("Companies.productAgnosticHeader")}
                              </StyledTableCell>
                              {isCountryInCreditCardList(country) && isCreditCardToggleEnabled ? ( 
                                <StyledTableCell data-testid="creditcardtestid" align="center">
                                  {t("Companies.individualCreditCard")}
                                </StyledTableCell>
                              ) : null}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {values.accounts &&
                              values.accounts.length > 0 &&
                              values.accounts.map((account, index) => (
                                <TableRow key={index}>
                                  <TableCell width="15%">
                                    <FormControl
                                      variant="outlined"
                                      size="small"
                                    >
                                      <Select
                                        native
                                        value={account.typeUId}
                                        onChange={(opt) => {
                                          setFieldValue(
                                            `accounts.${index}.typeUId`,
                                            opt.target.value
                                          );
                                          setFieldTouched(
                                            `accounts.${index}.typeUId`
                                          );
                                        }}
                                        inputProps={{
                                          id: `accounts.${index}.typeUId`,
                                          name: `accounts.${index}.typeUId`,
                                          title: "Type",
                                          "aria-label": "Type",
                                          "aria-required": true,
                                          "data-validation" : "client-validation-error"
                                        }}
                                        name={`accounts.${index}.typeUId`}
                                        error={
                                          (errors &&
                                            errors.accounts &&
                                            errors.accounts[index] &&
                                            (errors.accounts[
                                              index
                                            ] as FormikErrors<accountType>)
                                              .typeUId) !== undefined
                                            ? true
                                            : false
                                        }
                                      >
                                        {accountTypes?.map((opt: any) => (
                                          <option
                                            key={opt.value}
                                            value={opt.value}
                                          >
                                            {opt.text}
                                          </option>
                                        ))}
                                      </Select>
                                      {errors &&
                                        errors.accounts &&
                                        errors.accounts[index] &&
                                        (errors.accounts[
                                          index
                                        ] as FormikErrors<accountType>).typeUId &&
                                        touched &&
                                        touched.accounts &&
                                        touched.accounts[index] &&
                                        (touched.accounts[
                                          index
                                        ] as FormikTouched<accountType>)
                                          .typeUId ? (
                                        <span className={classes.errorWarning}>
                                          {
                                            (errors.accounts[
                                              index
                                            ] as FormikErrors<accountType>)
                                              .typeUId
                                          }
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </FormControl>
                                  </TableCell>
                                  <TableCell width="15%">
                                    <Field
                                      name={`accounts.${index}.nameReasonForHire`}
                                      id={`accounts.${index}.nameReasonForHire`}
                                      style={{
                                        paddingBottom: 0,
                                        paddingTop: 0,
                                      }}
                                      size="small"
                                      inputProps={{
                                        "aria-label": t(
                                          "Companies.nameOrReasonForHire"
                                        ),
                                      }}
                                      value={values.accounts[index].nameReasonForHire}
                                      onChange= {(e) => {
                                        setFieldValue(`accounts.${index}.nameReasonForHire`, e.target.value);
                                        setFieldTouched(`accounts.${index}.nameReasonForHire`);
                                      }}
                                      component={TextField}
                                      disabled={true}
                                      variant="outlined"
                                    />
                                  </TableCell>
                                  <TableCell width="15%">
                                    <Field
                                      name={`accounts.${index}.displayName`}
                                      id={`accounts.${index}.displayName`}
                                      style={{ padding: 0 }}
                                      size="small"
                                      inputProps={{
                                        "aria-label": "Please enter friendly account name",
                                        "data-validation" : "client-validation-error"
                                      }}
                                      value={values.accounts[index].displayName}
                                      onChange= {(e) => {
                                        setFieldValue(`accounts.${index}.displayName`, e.target.value);
                                        setFieldTouched(`accounts.${index}.displayName`);
                                      }}
                                      component={TextField}
                                      variant="outlined"
                                      placeholder={t("Companies.displayName")}
                                      error={
                                        (accountError &&
                                          accountError[index] &&
                                          accountError[index]
                                            .isDisplayNameerror) ||
                                          (errors &&
                                            errors.accounts &&
                                            errors.accounts[index] &&
                                            (errors.accounts[
                                              index
                                            ] as FormikErrors<accountType>)
                                              .displayName) !== undefined
                                          ? true
                                          : false
                                      }
                                    />
                                  </TableCell>
                                  <TableCell width="15%">
                                    <Field
                                      name={`accounts.${index}.accountNumber`}
                                      id={`accounts.${index}.accountNumber`}
                                      style={{ padding: 0 }}
                                      size="small"
                                      inputProps={{
                                        "aria-label": "Please enter Account Name",
                                        "aria-required": true,
                                        "data-validation" : "client-validation-error"
                                      }}
                                      value={values.accounts[index].accountNumber}
                                      onChange= {(e) => {
                                        setFieldValue(`accounts.${index}.accountNumber`, e.target.value);
                                        setFieldTouched(`accounts.${index}.accountNumber`);
                                      }}
                                      component={TextField}
                                      disabled={!account.isNewRow}
                                      variant="outlined"
                                      placeholder={t("Companies.AccountName")}
                                      error={
                                        (accountError &&
                                          accountError[index] &&
                                          accountError[index].isAccounterror) 
                                          ||
                                          (errors &&
                                            errors.accounts &&
                                            errors.accounts[index] &&
                                            (errors.accounts[
                                              index
                                            ] as FormikErrors<accountType>)
                                              .accountNumber )!== undefined
                                          ? true
                                          : false
                                      }
                                      
                                      helperText={(accountError &&
                                        accountError[index] &&
                                        accountError[index].isAccounterror) 
                                        ||
                                        (errors &&
                                          errors.accounts &&
                                          errors.accounts[index] &&
                                          (errors.accounts[
                                            index
                                          ] as FormikErrors<accountType>)
                                            .accountNumber )!== undefined  ? getAccountValidationErrorMessage(errors.accounts) : ""}
                                        
                                    />
                                  </TableCell>
                                  <TableCell width="15%">
                                 <Field
                                      data-testid={`billingNumberTestId-${index}`}
                                      name={`accounts.${index}.billingNumber`}
                                      id={`accounts.${index}.billingNumber`}
                                      style={{ padding: 0 }}
                                      size="small"
                                      disabled={initialDisabledState[index]}
                                      inputProps={{
                                        "aria-label": "Please enter Billing Number",
                                        "data-validation" : "client-validation-error"
                                      }}
                                      value={values.accounts[index].billingNumber}
                                      onChange= {(e) => {
                                        setFieldValue(`accounts.${index}.billingNumber`, e.target.value);
                                        setFieldTouched(`accounts.${index}.billingNumber`);
                                      }}
                                      component={TextField}
                                      variant="outlined"
                                      placeholder={t("Companies.billingNumber")}
                                      error={
                                        (accountError &&
                                          accountError[index] &&
                                          accountError[index]
                                            .isBillingNumbererror) ||
                                          (errors &&
                                            errors.accounts &&
                                            errors.accounts[index] &&
                                            (errors.accounts[
                                              index
                                            ] as FormikErrors<accountType>)
                                              .billingNumber) !== undefined
                                          ? true
                                          : false
                                          
                                      }
                                      helperText={(accountError &&
                                        accountError[index] &&
                                        accountError[index].isAccounterror) 
                                        ||
                                        (errors &&
                                          errors.accounts &&
                                          errors.accounts[index] &&
                                          (errors.accounts[
                                            index
                                          ] as FormikErrors<accountType>)
                                            .billingNumber )!== undefined  ? getBillingValidationErrorMessage(errors.accounts) : ""}
                                    />
                                  </TableCell>
                                  <TableCell width="5%">
                                    <IconButton
                                      name={`accounts.${index}.deleteAccount`}
                                      id={`accounts.${index}.deleteAccount`}
                                      onClick={() => {
                                        arrayHelpers.remove(index);
                                        setInitialDisabledState(prevState =>
                                          prevState.filter((_, idx) => idx !== index)
                                        );
                                        var arrayError = accountError.filter(
                                          (i) =>
                                            i.companyAccountUId !==
                                            account.companyAccountUId
                                        );
                                        setaccountError(arrayError);
                                        if (
                                          addAccountButtonRef.current !== null
                                        )
                                          addAccountButtonRef.current.focus();
                                          if(account.accountNumber !== "")
                                        dispatch(
                                          broadcastAnnouncement(
                                            t("Companies.accountRemoved", {
                                              AccountNumber:
                                                account.accountNumber
                                            })
                                          )
                                        );
                                        else
                                        dispatch(
                                          broadcastAnnouncement(
                                            t("Companies.accountRemoved", {
                                              AccountNumber:
                                               "row number " + (index +1)
                                            })
                                          )
                                        );
                                      }}
                                      aria-label={t("Delete")}
                                      title={t("Companies.deleteAccount")}
                                      role="img"
                                      size="small"
                                      style={{
                                        marginRight: 5,
                                        border: "0px solid #547D66",
                                        color: "#d02216",
                                      }}
                                    >
                                      <CancelIcon />
                                    </IconButton>
                                  </TableCell>
                                  <TableCell width="10%"
                                  >
                                    <Checkbox
                                      checked={account.productAgnostic}
                                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                      name={`accounts.${index}.productAgnostic`}
                                      color="primary"
                                      onChange={(e) => {
                                        var x = values.accounts
                                          ? values.accounts.length
                                          : 0;
                                        for (var i = 0; i < x; i++) {
                                          setFieldValue(
                                            `accounts.${i}.productAgnostic`,
                                            false
                                          );
                                        }
                                        setFieldValue(
                                          `accounts.${index}.productAgnostic`,
                                          e.target.checked
                                        );
                                        setFieldTouched(
                                          `accounts.${index}.productAgnostic`
                                        );
                                      }}
                                      inputProps={{
                                        "aria-label": t(
                                          "Companies.productAgnosticHeader"
                                        ),
                                      }}
                                    />
                                  </TableCell>
                                  {isCountryInCreditCardList(country) && isCreditCardToggleEnabled ? <TableCell width="15%">
                                  {errors?.accounts?.[index] &&
                                        (errors.accounts[
                                          index
                                        ] as FormikErrors<accountType>).isCreditCard ? (
                                        <span id = {`accounts.${index}.isCreditCard`} className={`${classes.errorWarning} jss196`}>
                                          {
                                            (errors.accounts[
                                              index
                                            ] as FormikErrors<accountType>)
                                              .isCreditCard
                                          }
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                     <Checkbox
                                      checked={account.isCreditCard}
                                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                      name={`accounts.${index}.isCreditCard`}
                                      color="primary"
                                      disabled={initialDisabledState[index]}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `accounts.${index}.isCreditCard`,
                                          e.target.checked
                                        );
                                        setFieldTouched(
                                          `accounts.${index}.isCreditCard`
                                        );
                                      }}
                                      inputProps={{
                                        "aria-label": t(
                                          "Companies.individualCreditCard"
                                        ),
                                      }}
                                    />
                                  </TableCell> : null}
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                        <Button
                          id="addCompanyAccountButton"
                          ref={addAccountButtonRef}
                          style={{
                            marginTop: "1em",
                            marginBottom: "1em",
                            marginLeft : "0.5em",
                            textTransform: "none",
                          }}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            arrayHelpers.push(newAccountRow);
                            var errorRow: accountErrorType = {
                              companyAccountUId:
                                newAccountRow.companyAccountUId,
                              isAccounterror: false,
                              isDisplayNameerror: false,
                              isBillingNumbererror: false,
                            };
                            const newaccountError: accountErrorType[] = [
                              ...accountError,
                              errorRow,
                            ];
                            setaccountError(newaccountError);
                            setTimeout(() => { dispatch(
                              broadcastAnnouncement(
                                "New Row for account added at position " + (values.accounts.length+1)
                              )
                            );}, 100); 
                            setnewAccountAdded(!newAccountAdded);
                          }}
                        >
                          {" "}
                          {t("Companies.addAccount")}{" "}
                        </Button>
                      </>
                    )}
                  />
                </TableContainer>

                {/* </Form> */}
              </Grid>
              <Grid style={{ marginTop: "1em" }} container justifyContent="flex-end">
                <Button
                  id="btnUpdate"
                  data-testid="savebuttontestid"
                  color="primary"
                  variant="contained"
                  disabled={!isValid}
                  type="submit"
                  style={{ textTransform: "none" }}
                >
                  {t("Companies.btnSave")}
                </Button>
                <Button
                  classes={{ outlinedPrimary: classes.outlinedButton }}
                  id="btnCancel"
                  color="primary"
                  variant="outlined"
                  type="button"
                  onClick={() => {
                    props.action("");
                  }}
                  style={{ marginLeft: "0.5em", textTransform: "none" }}
                >
                  {t("Companies.btnCancel")}
                </Button>
              </Grid>
            </Form>
          </Grid>
        )}
      </Formik>
    </Box>
  );
}
