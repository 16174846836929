import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useDispatch } from "react-redux";
import { clearAlertMessage } from "../components/store/actions/shared/alertActions";
import { CardMedia, CardActionArea } from "@mui/material";
import configParams from "configParams";

const ETDInstructionVideo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lng = queryParams.get("locale");
  var language = lng !== null ? lng.toString() : "en-GB";

  let apiAddress = window.location.hostname.endsWith("etd.enterprise.co.uk")
                  ? configParams.API_BASE_URL
                  : configParams.API_BASE_URL_EHI;  
  const baseURL = (window.location.hostname.indexOf("-ne")>0 || window.location.hostname.indexOf("-we")>0
                  ? apiAddress
                  : apiAddress.replace('prd-we-','').replace('prd-ne-','').replace('-we','').replace('-ne',''));
  const InstructionalVideoUserGuideURL =
    baseURL + "getETDInstructionalVideoUserGuide";

  useEffect(() => {
    document.title = "Enterprise Rent-A-Car -" + t("Footer.InstructionVideo");
    dispatch(clearAlertMessage());
  });
  return (
    <div style={{ marginLeft: "1em", marginBottom: "1em" }}>
      <h1>{t("Footer.InstructionVideo")}</h1>
      <br />
      <CardActionArea>
        <CardMedia
          component="video"
          controls
          autoPlay
          src={InstructionalVideoUserGuideURL + "/" + language}
        ></CardMedia>
      </CardActionArea>
    </div>
  );
};

export default ETDInstructionVideo;
