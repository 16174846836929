export interface ManualCardStartActivity {
    url: string;
    activityUrn: string;   
    contextId: string;
    success:boolean;
  }

  export interface ManualCardActivityResult {
    result : { cardDetails: CardDetails;
               cardAttributes: CardAttributes;   
              }
    errors: string[];
    success:boolean;
  }

  export interface CardDetails {
    nameProvided: string;
    maskedCardNumber: string;   
    expiration: CardExpiration;    
  }
  export interface CardExpiration {
    lastValidMonth: number;
    year: number;   
  }

  export interface CardAttributes {
    cardBrand: string;
    cardIssuer: string;   
  }
  export const ManualCardStartActivityInitialState: ManualCardStartActivity = {
    url: "",
    activityUrn: "",
    contextId: "",
    success:false,
  };
