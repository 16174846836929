import {isHiddenAdditionalParameters} from "helpers/additionalParameters"
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PrintIcon from "@mui/icons-material/Print";
import EmailIcon from "@mui/icons-material/Email";
import axiosInstance from "helpers/axiosInstance";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {isValidEtdEmailAddress} from "helpers/emailHelpers";

import {
  JourneyMethodType1,
  ReservationAdditionalInformationFieldTypeCode,
  ReservationStatusType,
  ReservationViewModel,
} from "./ReservationViewType";
import { useNavigate, useParams } from "react-router-dom";
import "components/home/home.css";
import { clearAlertMessage, showErrorAlertMessage, showSuccessAlertMessage } from "components/store/actions/shared/alertActions";
import { useDispatch } from "_testHelpers/react-redux-hooks";
import { useSelector } from "react-redux";
import { ArrowBack } from "@mui/icons-material";
import { authService } from "services/authService";
import { getEnumKeyByEnumValue } from "helpers/enumMapping";
import { getLocaleCurrency, getLocaleDate } from "helpers/localization";
import { ReservationAdditionalInformationFieldViewModel } from "components/admin/companies/companiesType";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { AppState } from "components/store/reducers/rootReducer";
import { translateOTA } from "helpers/otaTranslateHelper";


const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    paddingRight: 30,
    paddingTop: 15,
    paddingBottom: 15,
  },
  title: {
    fontSize: "1.4em",
    paddingLeft: 30,
    paddingTop: 10,
  },
  subtitle: {
    fontSize: "1.2em",
    marginTop: 10,
    marginBottom: 10,
    fontWeight: "normal",
  },
  tableHeader: {
    fontWeight: "bold",
    padding: 4,
    borderBottom: "none",
    fontSize: "1.1em",
  },
  tableBody: {
    fontSize: "1.1em",
    padding: 4,
    borderBottom: "none",
    wordBreak: "break-word",
  },
  detail: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
  },
  rootcell: {
    "& .MuiTableCell-root": {
      verticalAlign: "top",
      borderBottom: "none",
      padding: "10px",
    },
  },
  divider: {
    backgroundColor: "#000",
    height: "0.8px",
    marginRight: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export function getBookingReference(
  input: ReservationAdditionalInformationFieldViewModel, locale: string
) {
  const localeString = locale === "en" ? "en-GB" : "de-DE";
  var singleReference;
  switch (input.fieldTypeCode) {
    case ReservationAdditionalInformationFieldTypeCode.Date:
      singleReference =
        input.fieldName +
        " = " +
        (input.fieldValueDateString === ""
          ? ""
          : new Date(
              parseInt(input.fieldValueDateString.substr(0, 4)),
              parseInt(input.fieldValueDateString.substr(4, 2)) - 1,
              parseInt(input.fieldValueDateString.substr(6, 2))
            ).toLocaleString(localeString, {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            }));
      break;
    default:
      singleReference =
        input.fieldName + " = " + (input.fieldValue ? input.fieldValue : "");
  }
  return singleReference;
}

const ViewDR = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const locale = authService.getUserLocale();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [resData, setresData] = React.useState<ReservationViewModel>();
  const { companyInfo } = useSelector(
    (state: AppState) => state.companyInfo || {}
  );
  const { journeyID, reference } = useParams<{
    journeyID: string;
    reference: string;
  }>();
  const titleRef = React.useRef<HTMLElement>(null);
  const printRef = React.useRef<HTMLButtonElement>(null);
  const copyInvoiceRef = React.useRef<HTMLButtonElement>(null);
const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

const handleInvoiceClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  setInvoiceEmail(getDefaultInvoiceEmailAddresses());
  setAnchorEl(event.currentTarget);
};

const getDefaultInvoiceEmailAddresses = () => {
  return authService.getUserEmail().toLowerCase();
}

const handleInvoiceClose = () => {
  setAnchorEl(null);
};

const handleSendInvoice = async () => {
  const invoiceEmails = invoiceEmail.split(",").map(email => email.trim());
  const allEmailsValid = invoiceEmails.every(isValidEtdEmailAddress);
  if(allEmailsValid)
    {
      let req = {"emails": invoiceEmails, "reservationNumber": resData?.reservationNumber.number}
      await SendInvoiceRequest(req)
    }
    else
    {
      dispatch(showErrorAlertMessage(t("Users.Errors.invalidEmail")))
    }
    setAnchorEl(null);
};

const isInvoiceDgOpen = Boolean(anchorEl);
const invoicePopOverId = isInvoiceDgOpen ? 'simple-popover' : undefined;
const [invoiceEmail, setInvoiceEmail] = useState<string>("");

  appInsights.trackPageView();
  React.useEffect(() => {
    document.title = t("ViewResTitle");
    dispatch(clearAlertMessage());
    setLoading(true);
    GetReservations();
    if (printRef.current != null && !props.disableAutoFocus)
      printRef.current.focus();
  }, []);

  React.useEffect(() => {
   
    if (copyInvoiceRef.current != null )
      copyInvoiceRef.current.focus();
  }, [resData?.reservationStatus]);
  const GetReservations = async () => {
    try {
      const response = await axiosInstance.get(
        `reservation/details/${journeyID}/${reference}`
      );
      setresData(response.data);
      setLoading(false);
      console.log("response.data: ", response.data);
    } catch (err) {
      console.log("error occured : ", err);
      appInsights.trackException({
        exception: new Error("An exception has occured " + err),
        id: "ViewDR",
        severityLevel: SeverityLevel.Error,
      });
    }
  };
  const SendInvoiceRequest = async (invoiceRequest) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        `invoices/sendcopyinvoice`, invoiceRequest
      );
      dispatch(showSuccessAlertMessage(t("Res.SendInvoiceCopyMessage")))
      setLoading(false);
      console.log("response.data: ", response.data);
    } catch (err) {
      setLoading(false);
      console.log("error occured in sending Invocie request: ", err);
      dispatch(showErrorAlertMessage(t("CustomErrorGenericErrorMsg")));
      appInsights.trackException({
        exception: new Error("An exception has occured while sending Invoice Request" + err),
        id: "Invoice Request",
        severityLevel: SeverityLevel.Error,
      });
    }
  };
  function isNullOrWhitespace(input) {
    return !input || !input.trim();
  }

 
  let history = useNavigate();
  const selectedCar = resData?.carClass.carsInformation.classInfo.find(
    (x) => (x.modelCode = resData?.carClass.carClassCode)
  );
  const selectedBrandInfo = selectedCar?.brandInfo.find(
    (x) => x.brand === resData?.carClass.brand
  );
  
  let distanceUnit = "KM";
  if (resData?.carClass.distance?.unit.toUpperCase() === "MILE"){
    distanceUnit = "MI"
  } 
  console.log("Selected Car fees", selectedCar);

  return (
      <Box boxShadow={3} m={3}>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress />
        </Backdrop>
        <Grid
          data-testid="viewDR"
          className={classes.rootcell}
          container
          spacing={3}
        >
          <Grid id="titleHeader" item xs={12} md={7} className={classes.root}>
            <Typography ref={titleRef} className={classes.title} variant="h1">
              {t("Res.ReservationDetails")}
            </Typography>
            <Divider
              className={classes.divider}
              style={{ marginTop: 10, marginLeft: 30 }}
            />
          </Grid>
          <Grid item  md={5} id="actionButtons">
            <Box  style={{ float: "right" }}>
            {(resData?.isCreditCardToggleEnabled && resData?.reservationStatusUId.toLowerCase() === ReservationStatusType.cardpayment.toLocaleLowerCase()) 
              && (
              <Button 
                id="copyInvoice"
                name="copyInvoice"                
                data-testid="btnCopyInvoice"
                aria-describedby={invoicePopOverId}               
                size="medium"
                onClick= {handleInvoiceClick}                  
                ref={copyInvoiceRef}
                title={t("Res.CopyInvoiceTitle")}
                aria-label={t("Res.CopyInvoice")}                
                style={{
                  marginRight: 5  ,marginTop: 5                  
                                
                }}
                startIcon={<EmailIcon />}
                variant="contained"
              >               
                {t("Res.CopyInvoice")}
              </Button>
              
            )}
              <Button
                id="printJourneyButton"
                size="medium"
                onClick={() => {
                  appInsights.trackEvent({
                    name: "View DR Print",
                    properties: { Reference: reference },
                  });
                  dispatch(clearAlertMessage());
                  window.print();
                }}
                ref={printRef}
                title={t("Res.PrintTitle")}
                aria-label={t("Res.Print")}
               
                style={{
                  marginRight: 5, marginTop: 5   
                }}
                variant="contained"
                startIcon={<PrintIcon />}
              >               
                {t("Print")}
              </Button>

              <Button
                id="backButton"
                size="medium"
                onClick={() => history(-1)}
                title={t("BackTitle")}
                aria-label={t("Back")}               
                style={{
                  marginRight: 5 , marginTop: 5                
                }}
                startIcon={<ArrowBack />}
                variant="contained"
              >               
                {t("Back")}
              </Button>
            </Box>
            <Popover
                  id={invoicePopOverId}
                  data-testid="InvoicePopover"
                  open={isInvoiceDgOpen}
                  anchorEl={anchorEl}                  
                  onClose={handleInvoiceClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <Box>
                    <Grid style={{width:'260px',height:'120px', padding:'5px'}}>
                      <Grid item>
                    <Typography>{t("Users.emailaddress")}</Typography></Grid>
                    <Grid item>
                    <TextField fullWidth id="invoiceEmail" name="invoiceEmail" placeholder={t("Users.emailPlaceholder")} defaultValue={invoiceEmail} onChange={(e) => setInvoiceEmail(e.target.value)}/></Grid>
                    <Grid item style={{textAlign:"right", marginTop:'5px'}}>
                    <Button id="btnSendInvoice"  color="primary"  variant="contained" onClick={() =>  handleSendInvoice()}  >{t("Submit")}</Button>
                    </Grid>
                    </Grid>
              </Box>
            </Popover>
          </Grid>
          <Grid id="printtitleHeader" style={{ display: "none" }} item xs={12}>
            <Typography align="center" className={classes.title} variant="h1">
              {t("Res.ThankYouForYourReservation")}
            </Typography>
            <div style={{ paddingLeft: 30 }}>
              <Typography
                align="center"
                variant="h2"
                style={{
                  paddingBottom: 10,
                  color: "#d02216",
                  fontSize: "1.2em",
                  fontWeight: "normal",
                }}
              >
                {t("Res.PleaseSeeConfirmationDetails")}
              </Typography>
              <Divider className={classes.divider} />{" "}
            </div>
          </Grid>
          <Grid item xs={12} md={6} className={classes.root}>
            <Box style={{ paddingLeft: 30 }}>
              <Typography
                className={classes.subtitle}
                style={{ marginTop: 0 }}
                variant="h2"
              >
                {t("Res.DriverDetails")}
              </Typography>
              <Divider className={classes.divider} />
              <TableContainer id="detailsTable" className={classes.detail}>
                <Table aria-label="details table">
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        width="33.33%"
                        className={classes.tableHeader}
                      >
                        <Typography variant="h2">{t("DriverName")}</Typography>
                      </TableCell>
                      <TableCell id="DriverName" className={classes.tableBody}>
                        <Typography variant="body1">
                          {resData?.driverName}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        <Typography variant="h2">
                          {t("Res.DriverEmail")}
                        </Typography>
                      </TableCell>
                      <TableCell id="DriverEmail" className={classes.tableBody}>
                        <Typography variant="body1">
                          {resData?.driverEmail}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {/* {resData?.driverDeclaration && resData?.additionalDriver?length>0 && {} */}
                    {resData?.driverDeclaration &&
                      resData?.additionalDriver &&
                      resData?.additionalDriver.length > 0 && (
                        <TableRow>
                          <TableCell
                            component="th"
                            className={classes.tableHeader}
                          >
                            <Typography variant="h2">
                              {t("AdditionalDriver")}
                            </Typography>
                          </TableCell>
                          <TableCell
                            id="AdditionalDrivers"
                            className={classes.tableBody}
                          >
                            {resData?.additionalDriver?.map(
                              (additionalDriver, index) => {
                                return (
                                  <Typography key={index} variant="body1">
                                    {" "}
                                    {additionalDriver.firstName +
                                      " " +
                                      additionalDriver.lastName}
                                    <br />{" "}
                                  </Typography>
                                );
                              }
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    {(resData?.loyalty?.loyaltyId ||
                      resData?.loyalty?.enableLoyalty) && (
                      <TableRow>
                        <TableCell
                          component="th"
                          className={classes.tableHeader}
                        >
                          <Typography variant="h2">
                            {t(
                              "ReservationWizard.reservationPage.labelLoyaltyId"
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell id="LoyaltyId" className={classes.tableBody}>
                          <Typography variant="body1">
                            {resData?.loyalty?.loyaltyId}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        <Typography variant="h2">
                          {t("Res.DisplayName")}
                        </Typography>
                      </TableCell>
                      <TableCell id="DisplayName" className={classes.tableBody}>
                        <Typography variant="body1">
                          {resData?.displayName}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        <Typography variant="h2">
                          {t("Res.LastModifiedBy")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        id="LastModifiedBy"
                        className={classes.tableBody}
                      >
                        <Typography variant="body1">
                          {resData?.bookerName}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        <Typography variant="h2">
                          {" "}
                          {t("Res.BookingReferences")}
                        </Typography>
                      </TableCell>
                      {resData?.additionalInformation === null ||
                      resData?.additionalInformation.additionalInformationFields
                        .length === 0 ? (
                        <TableCell
                          id="BookingReferences"
                          className={classes.tableBody}
                        ></TableCell>
                      ) : (
                        <TableCell
                          id="BookingReferences"
                          data-testid="BookingReferences"
                          className={classes.tableBody}
                        >
                          {resData?.additionalInformation.additionalInformationFields?.map(
                            (additionalInformation, index) => {
                              return (
                                (!isHiddenAdditionalParameters(additionalInformation.fieldName) &&
                                <Typography data-testid="bookingReferenceData" key={index} variant="body1">
                                  {" "}
                                  {getBookingReference(
                                    additionalInformation, locale.toString()
                                  )}{" "}
                                  <br />{" "}
                                </Typography>)
                              );
                            }
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography className={classes.subtitle} variant="h2">
                {t("Res.BranchDetails")}
              </Typography>
              <Divider className={classes.divider} />
              <TableContainer
                id="branchDetailsTable"
                className={classes.detail}
              >
                <Table aria-label="Branch table">
                  <TableBody>
                    <TableRow>
                      <TableCell
                        width="33.33%"
                        component="th"
                        className={classes.tableHeader}
                      >
                        <Typography variant="h2">
                          {t("Res.BranchAddress")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        id="BranchAddress"
                        width="66.67%"
                        className={classes.tableBody}
                      >
                        <Typography variant="body1">
                          {resData?.branchAddress}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        <Typography variant="h2">
                          {t("Res.BranchTelephone")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        id="BranchTelephone"
                        className={classes.tableBody}
                      >
                        <Typography variant="body1">
                          {resData?.branchTelephone}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        <Typography variant="h2">
                          {t("Res.VehicleClass")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        id="VehicleClass"
                        className={classes.tableBody}
                      >
                        <Typography variant="body1">
                          {resData?.vehicleClass}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        <Typography variant="h2"> {t("Res.Brand")}</Typography>
                      </TableCell>
                      <TableCell
                        id="FullBrandName"
                        className={classes.tableBody}
                      >
                        <Typography variant="body1">
                          {resData?.fullBrandName}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        <Typography variant="h2"> {t("Res.Notes")}</Typography>
                      </TableCell>
                      <TableCell id="Notes" className={classes.tableBody}>
                        <Typography variant="body1">
                          {resData?.notes}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {resData?.locations?.startLocation?.isAirportLocation && (
                      <TableRow>
                        <TableCell
                          component="th"
                          className={classes.tableHeader}
                        >
                          <Typography variant="h2">
                            {" "}
                            {t("Res.flightNumber")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          id="FlightNumber"
                          className={classes.tableBody}
                        >
                          <Typography variant="body1">
                            {resData?.flightNumberViewModel?.flightNumber}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography className={classes.subtitle} variant="h2">
                {t("Res.JourneyDetails")}
              </Typography>
              <Divider className={classes.divider} />
              <TableContainer
                id="reservationdetailsTable"
                className={classes.detail}
              >
                <Table>
                  <TableBody>
                    {resData?.showReservationDetails && (
                      <>
                        <TableRow>
                          <TableCell
                            width="33.33%"
                            component="th"
                            className={classes.tableHeader}
                          >
                            <Typography variant="h2">
                              {t("Res.ReservationNumber")}
                            </Typography>
                          </TableCell>
                          <TableCell
                            id="ReservationNumber"
                            className={classes.tableBody}
                          >
                            <Typography variant="body1">
                              {resData?.reservationNumberString}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            component="th"
                            className={classes.tableHeader}
                          >
                            <Typography variant="h2">
                              {t("Res.ReservationStatus")}
                            </Typography>
                          </TableCell>
                          <TableCell
                            id="ReservationStatus"
                            className={classes.tableBody}
                          >
                            <Typography variant="body1">
                              {t(
                                "Res." +
                                  getEnumKeyByEnumValue(
                                    ReservationStatusType,
                                    resData.reservationStatusUId
                                  )
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        <Typography variant="h2">
                          {" "}
                          {t("StartDateTime")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        id="StartDateTime"
                        className={classes.tableBody}
                      >
                        <Typography variant="body1">
                          {resData?.startDateTimeString !== undefined
                            ? getLocaleDate(resData.startDateTimeString, locale)
                            : ""}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        <Typography variant="h2">
                          {" "}
                          {t("EndDateTime")}
                        </Typography>
                      </TableCell>
                      <TableCell id="EndDateTime" className={classes.tableBody}>
                        <Typography variant="body1">
                          {resData?.endDateTimeString !== undefined
                            ? getLocaleDate(resData.endDateTimeString, locale)
                            : ""}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {resData?.reservationNewEndDateTime && (
                      <TableRow>
                        <TableCell
                          component="th"
                          className={classes.tableHeader}
                        >
                          {t("Res.RequestedEndDateTime")}
                        </TableCell>
                        <TableCell
                          id="requestedEndDateTime"
                          className={classes.tableBody}
                        >
                          {resData?.reservationNewEndDateTimeString !==
                          undefined
                            ? getLocaleDate(
                                resData.reservationNewEndDateTimeString,
                                locale
                              )
                            : ""}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        <Typography variant="h2">
                          {" "}
                          {t("StartLocation")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        id="StartLocation"
                        className={classes.tableBody}
                      >
                        {resData?.startLocationString
                          ?.replace("&#39;", "'")
                          .split("&lt;br/&gt;")
                          ?.map((p, index) => {
                            return (
                              <Typography key={index} variant="body1">
                                {p} <br />{" "}
                              </Typography>
                            );
                          })}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        <Typography variant="h2">
                          {" "}
                          {t("Res.StartMethod")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        id="StartMethod"
                        className={classes.tableBody}
                        style={{ paddingTop: 8, paddingBottom: 8 }}
                      >
                        <Typography variant="body1">
                          {t(
                            "Res." +
                              JourneyMethodType1[
                                Number(
                                  resData?.locations?.startLocation
                                    .selectedMethodTypeId
                                )
                              ]
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {!isNullOrWhitespace(resData?.startServicingBranch) && (
                      <TableRow>
                        <TableCell
                          component="th"
                          className={classes.tableHeader}
                        >
                          <Typography variant="h2">
                            {t("Res.ServicingBranch")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          id="StartServicingBranch"
                          className={classes.tableBody}
                        >
                          <Typography variant="body1">
                            {resData?.startServicingBranch}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        <Typography variant="h2">
                          {" "}
                          {t("EndLocation")}
                        </Typography>
                      </TableCell>
                      <TableCell id="EndLocation" className={classes.tableBody}>
                        {resData?.endLocationString
                          ?.replace("&#39;", "'")
                          .split("&lt;br/&gt;")
                          ?.map((p, index) => {
                            return (
                              <Typography key={index} variant="body1">
                                {p} <br />{" "}
                              </Typography>
                            );
                          })}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" className={classes.tableHeader}>
                        <Typography variant="h2">
                          {" "}
                          {t("Res.EndMethod")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        id="EndMethod"
                        className={classes.tableBody}
                        style={{ paddingTop: 8, paddingBottom: 8 }}
                      >
                        <Typography variant="body1">
                          {" "}
                          {t(
                            "Res." +
                              JourneyMethodType1[
                                Number(
                                  resData?.locations?.endLocation
                                    .selectedMethodTypeId
                                )
                              ]
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {!isNullOrWhitespace(resData?.startServicingBranch) && (
                      <TableRow>
                        <TableCell
                          component="th"
                          className={classes.tableHeader}
                        >
                          <Typography variant="h2">
                            {t("Res.ServicingBranch")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          id="EndServicingBranch"
                          className={classes.tableBody}
                        >
                          <Typography variant="body1">
                            {resData?.endServicingBranch}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
          {!resData?.isStartDateInPast && (
            <Grid item xs={12} md={6}>
              <Box id="DRCostDetails" style={{ paddingLeft: 30 }}>
                <Typography
                  className={classes.subtitle}
                  style={{ marginTop: 0 }}
                  variant="h2"
                >
                  {t("Res.EstimatedCostExtrasDetails")}
                </Typography>
                <Divider className={classes.divider} />
                <Box className={classes.detail} style={{ paddingTop: 5 }}>
                  <Typography
                    variant="h3"
                    className={classes.tableHeader}
                    style={{ marginBottom: 10 }}
                  >
                    {t("Res.VehicleClass")}
                  </Typography>
                  <Divider className={classes.divider} />
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {selectedCar?.vehicleClass != null && (
                          <TableRow>
                            <TableCell
                              className={classes.tableBody}
                              width="40%"
                            >
                              {" "}
                              {translateOTA(
                                selectedCar?.vehicleClass || "",
                                t
                              )}{" "}
                            </TableCell>
                            <TableCell
                              className={classes.tableBody}
                              width="30%"
                            >
                              {selectedBrandInfo?.pricePerBaseWithCurrency} /{" "}
                              {translateOTA(
                                selectedBrandInfo?.unitName || "",
                                t
                              )}{" "}
                            </TableCell>
                            <TableCell
                              className={classes.tableBody}
                              width="30%"
                            >
                              {selectedBrandInfo?.baseRatePriceWithCurrency}
                            </TableCell>
                          </TableRow>
                        )}
                        {selectedBrandInfo?.additionalRentalCharge &&
                          selectedBrandInfo?.additionalRentalCharge
                            .filter((x) => x.rate > 0)
                            .map((p, index) => { 
                              return ( p.description.toUpperCase().toString() === "MILEAGE CHARGE" ?
                              "" 
                              :
                                <TableRow key={index}>
                                  <TableCell
                                    className={classes.tableBody}
                                    width="40%"
                                  >
                                    {p.description.toLowerCase() ===
                                    "drop charge"
                                      ? translateOTA(p.description, t)
                                      : ""}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableBody}
                                    width="30%"
                                  >
                                    {p.description.toLowerCase() ===
                                    "drop charge"
                                      ? ""
                                      : getLocaleCurrency(
                                          p.rateWithCurrency,
                                          locale,
                                          p.currency
                                        ) +
                                        " / " +
                                        translateOTA(p.unitName || "", t)}{" "}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableBody}
                                    width="30%"
                                  >
                                    {p.rateWithCurrency}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {((selectedBrandInfo?.extrasOptions != null &&
                    selectedBrandInfo?.extrasOptions.extras != null &&
                    selectedBrandInfo?.extrasOptions.extras.some(
                      (x) => x.isSelected
                    )) ||
                    (selectedBrandInfo?.includedExtras != null &&
                      selectedBrandInfo?.includedExtras.length > 0)) && (
                    <>
                      <Typography
                        variant="h3"
                        style={{ marginTop: 10, marginBottom: 10 }}
                        className={classes.tableHeader}
                      >
                        {t("Res.Extras")}
                      </Typography>
                      <Divider className={classes.divider} />
                      <TableContainer>
                        <Table>
                          <TableBody>
                            {selectedBrandInfo?.includedExtras != null &&
                              selectedBrandInfo.includedExtras.length > 0 &&
                              selectedBrandInfo?.includedExtras.map(
                                (p, index) => {
                                  return (
                                    <TableRow key={index}>
                                      <TableCell
                                        className={classes.tableBody}
                                        width="40%"
                                      >
                                        {translateOTA(p.description, t)}
                                      </TableCell>
                                      <TableCell
                                        className={classes.tableBody}
                                        width="30%"
                                      ></TableCell>
                                      <TableCell
                                        className={classes.tableBody}
                                        width="30%"
                                      >
                                        {getLocaleCurrency(
                                          p.totalUnitPriceWithCurrency,
                                          locale,
                                          p.currency
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                            {selectedBrandInfo?.extrasOptions != null &&
                              selectedBrandInfo?.extrasOptions.extras != null &&
                              selectedBrandInfo?.extrasOptions.extras.some(
                                (x) => x.isSelected
                              ) && (
                                <>
                                  {selectedBrandInfo?.extrasOptions.extras
                                    .filter((x) => x.isSelected)
                                    .map((p, index) => {
                                      return (
                                        <TableRow key={index}>
                                          <TableCell
                                            width="40%"
                                            className={classes.tableBody}
                                          >
                                            {translateOTA(p.description, t) +
                                              " "}{" "}
                                            {p.isCountable
                                              ? "x" + p.quantity
                                              : ""}
                                          </TableCell>
                                          <TableCell
                                            width="30%"
                                            className={classes.tableBody}
                                          >
                                            {p.isCountable
                                              ? getLocaleCurrency(
                                                  p.unitPriceWithCurrency,
                                                  locale,
                                                  p.currency
                                                ) +
                                                "/" +
                                                t("Res.PerUnit")
                                              : ""}
                                          </TableCell>
                                          <TableCell
                                            width="30%"
                                            className={classes.tableBody}
                                          >
                                            {getLocaleCurrency(
                                              p.totalPriceWithCurrency,
                                              locale,
                                              p.currency
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                </>
                              )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}

                  {selectedCar?.fees != null && selectedCar.fees.length > 0 && (
                    <>
                      <Typography
                        variant="h3"
                        style={{ marginTop: 10, marginBottom: 10 }}
                        className={classes.tableHeader}
                      >
                        {t("Res.TaxesAndFees")}
                      </Typography>
                      <Divider className={classes.divider} />
                      <TableContainer>
                        <Table id="tableFees">
                          <TableBody>
                            {selectedCar.fees.map((p, index) => {
                              return (
                                <TableRow 
                                  data-testid={["MILEAGE CHARGE", "UNLIMITED MILEAGE"].includes(p.description.toUpperCase()) ? "mileageCaps" : index.toString()}
                                  key={p.description}
                                >
                                  <TableCell
                                    id={p.description.replace(/ /g, "")+"-DESCRIPTION"}
                                    width="40%"
                                    className={classes.tableBody}
                                    style={{whiteSpace: 'pre-line'}}
                                  >
                                    {p.description.toUpperCase() === "MILEAGE CHARGE" ?
                                    t("Res.OTA.MileageCharge", { quantity: resData?.carClass.distance.quantity, unit: distanceUnit, baseAmount: p.baseAmount, currency: p.currency}) :
                                    translateOTA(p.description, t)
                                    }
                                  </TableCell>
                                  <TableCell
                                    width="30%"
                                    className={classes.tableBody}
                                  ></TableCell>
                                  <TableCell
                                    id={p.description.replace(/ /g, "")}
                                    width="30%"
                                    className={classes.tableBody}
                                  >
                                    {p.includedInRate
                                      ? t("Res.Included")
                                      : p.totalAmount.toLocaleString(locale, {
                                          minimumFractionDigits: 2,
                                          useGrouping: false,
                                        }) +
                                        " " +
                                        p.currency}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                  <p
                    className={classes.tableBody}
                    style={{ fontSize: "0.9em", color: "#006639" }}
                  >
                    {t("Res.FeeDisclaimer")}
                  </p>
                  <Divider className={classes.divider} />
                  <TableContainer>
                    <Table id="TotalEstimatedCost">
                      <TableBody>
                        <TableRow>
                          <TableCell width="40%" className={classes.tableBody}>
                            <strong>
                              {companyInfo?.isDriverValidityActivated
                                ? t("Res.BBCTotalEstimatedCost")
                                : t("Res.TotalEstimatedCost")}
                            </strong>
                          </TableCell>
                          <TableCell
                            width="30%"
                            className={classes.tableBody}
                          ></TableCell>
                          <TableCell
                            id="TotalEstimatedCostWithCurrency"
                            width="30%"
                            className={classes.tableBody}
                          >
                            <strong>
                              {
                                resData?.carClass.carsInformation
                                  .totalEstimatedCostWithCurrency
                              }
                            </strong>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {companyInfo?.isDriverValidityActivated &&
                    resData?.isInternationalStartLocation && (
                      <>
                        <div id="BBCCustomNote">
                          <Typography
                            variant="h3"
                            className={classes.tableBody}
                            style={{
                              marginTop: 10,
                              marginBottom: 10,
                              fontWeight: "bold",
                            }}
                          >
                            <div id="BBCCustomNoteLabel">
                              {t("Res.BBCNoteLabel")}
                            </div>
                            <div id="BBCCustomNoteDecription">
                              {t("Res.BBCNote")}
                            </div>
                          </Typography>
                          <Typography
                            id="BBCTransactionNote"
                            variant="h3"
                            className={classes.tableBody}
                            style={{
                              marginTop: 10,
                              marginBottom: 10,
                              fontWeight: "bold",
                            }}
                          >
                            {t("Res.BBCTransactionNote")}
                          </Typography>
                        </div>
                      </>
                    )}
                  <Typography
                    variant="h3"
                    style={{ marginTop: 10, marginBottom: 10 }}
                    className={classes.tableHeader}
                  >
                    {t("Res.MethodOfPayment")}
                  </Typography>
                  <Divider className={classes.divider} />
                  <div id="BillToAccountLabel">
                    {resData?.reasonForHire.isPayAtCounter ? (
                      <Typography className={classes.tableBody}>
                        {t("Res.PointOfRental")}
                      </Typography>
                    ) : (
                      <Typography className={classes.tableBody}>
                        {t("Res.billToAccount")}
                      </Typography>
                    )}
                  </div>
                  <div>
                  {resData?.isCreditCardToggleEnabled && resData?.isCreditCard && (
                    <Typography id="viewPaymentDisclaimer"  variant="body2"  style={{ fontSize: "0.9em", color: "#006639" }}>
                      {t("Res.PaymentDisclaimer")}
                    </Typography>
                  )}
                </div>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid
          id="DailyRentalDisclaimerText"
          item
          xs={12}
          md={12}
          style={{ margin: 20 }}
        >
          <Typography className={classes.tableHeader}>
            {" "}
            {t("Res.DailyRentalDisclaimerText")}{" "}
          </Typography>
        </Grid>
      </Box>
    
  );
};

export default ViewDR;


