import Header from "./header";
import Footer from "./footer";
import AlertMessage from "./alert";
import Announcement from "./announcement";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { authService } from "services/authService";
import React from "react";
type Props = {
  children: JSX.Element;
};

export default function Layout({ children }: Readonly<Props>) {
  const [counter, setCounter] = useState<number>(0);
  const location = useLocation();

  useEffect(() => {
    console.log("Changing page from layout", location);

    const userInfo = authService.currentUserValue;
    if (!userInfo) {
      console.log("User information is not available");
      return;
    }
    try {
      if ((window as any)?.pendo?.isReady()) {
        if (counter === 0) {
          console.log(
            "User has accepted cookies - setting Pendo info for the first time"
          );
          (window as any).pendo.identify({
            account: {
              id: userInfo.companyUId,
              name: userInfo.companyName,
            },
            visitor: {
              accountid: userInfo.companyUId,
              applocale: userInfo.isoLanguageCode,
              country: userInfo.companyCountryCode,
              id: userInfo.b2cobjectId,
              role: userInfo.role,
            },
          });
          setCounter(1);
        } else {
          console.log(
            "Pendo was already enabled the first time - no need to call 'identify' "
          );
        }
      } else {
        console.log("User has rejected cookies - keep as anonymous user");
        setCounter(0);
      }
    } catch (error) {
      console.error("Error setting Pendo info:", error);
    }
  }, [location]);

  return (
    <>
      <Header />
      <AlertMessage />
      <Announcement />
      <Box
        role="main"
        id="main"
        px={1}
        style={{ minHeight: window.innerHeight / 2 }}
      >
        {children}
      </Box>
      <Footer />
    </>
  );
}
