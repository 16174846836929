import React from "react";
import { useRef } from "react";
import FocusError from "../../../controls/formik/FocusOnError";
import makeStyles from '@mui/styles/makeStyles';
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { companyProfileType } from "./companiesType";
import {
  Box,
  FormControl,
  Backdrop,
  Button,
  Grid,
  Typography,
  Select,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  DialogActions,
  Checkbox,
  TextField
} from "@mui/material";
import axiosInstance from "../../../helpers/axiosInstance";
import * as Yup from "yup";
import {
  showSuccessAlertMessage,
  showErrorAlertMessage,
  clearAlertMessage,
} from "../../store/actions/shared/alertActions";
import { broadcastAnnouncement } from "../../store/actions/shared/announcementActions";
import { useDispatch } from "react-redux";
import { BorderAllTwoTone } from "@mui/icons-material";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { appInsights } from "helpers/appInsights";
import { isCountryInRentalManagementList } from "helpers/rentalManagement";

const useStyles = makeStyles({
  root: {
    "& .MuiFormHelperText-root": {
      color: "#DF0000",
      fontSize: 12,
      marginLeft: "15px",
    },
    "& ::placeholder": {
      fontStyle: "italic",
      color: "#000",
      opacity: 0.95,
    },
  },
  outlinedButton: {
    border: "1px solid rgba(0, 102, 57, 0.95);",
  },
  inputRoot: {
    fontSize: 30,
  },
  labelRoot: {
    fontSize: 30,
    color: "red",
    "&$labelFocused": {
      color: "purple",
    },
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: 12,
    marginLeft: "15px",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },

  labelFocused: {},
});

const InitialValue: companyProfileType = {
  companyName: "",
  reasonForHireLabel: "",
  isDeleted: false,
  isNew: true,
  countries: {
    selectedValue: "",
    selectedText: "",
    items: [
      {
        disabled: false,
        group: null,
        selected: false,
        text: "",
        value: "",
      },
    ],
  },
  boboEnabled: false,
  isBOBOToggleEnabled: false,
  isAllowAdminToAccessAllJourneys: false,
  isAppDriverValidityActivated: false,
  isCompanyDriverValidityActivated: false,
  isAllowAdminToAccessAllJourneysToggleEnabled: false,
  isRentalMgmtLocked: false
};
export default function CompanyProfile(props) {
  const [loading, setLoading] = React.useState(false);
  const [companyProfile, setcompanyprofile] =
    React.useState<companyProfileType>(InitialValue);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const titleRef = React.useRef<HTMLDivElement>(null);
  const btnYesRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = React.useState(false);
  const companyNameRef = React.useRef<HTMLInputElement>(null);
  const boboOptions = [
    { id: true, text: "Yes" },
    { id: false, text: "No" },
  ];
  const [isRmToggleDisabled, setRmToggleDisabled] = React.useState(false);

  React.useEffect(() => {
    appInsights.trackEvent({
      name: "Company Profile",
      properties: { companyId: props.companyId },
    });
    setcompanyprofile(props);
    setRmToggleDisabled(false);
  }, [props]);


  function validateSelectField(value) {
    console.log("profile value=", value["selectedValue"]);
    if (
      typeof value["selectedValue"] === "undefined" ||
      value["selectedValue"] === "" ||
      value["selectedValue"] === null
    )
      return false;
    else return true;
  }

  function isError(errorBool){
    return errorBool 
  }

  const profileValidationSchema = Yup.object().shape({
    companyName: Yup.string()
      .required(t("Companies.CompanyNameRequiredError"))
      .nullable()
      .max(60, t("Companies.CompanyNameLengthError")),
    reasonForHireLabel: Yup.string()
      .notRequired()
      .nullable()
      .max(120, t("Companies.ReasonForHireLengthError")),
    countries: Yup.object()
      .test("len", t("Companies.CountryRequiredError"), (value) =>
        validateSelectField(value)
      )
      .required(),
    boboEnabled: Yup.boolean()
      .notRequired()
      .test(
        "BoboValidation",
        t("Companies.BoboValidationError"),
        (boboEnabled, values) => {
          let isBOBOToggleEnabled = values.parent.isBOBOToggleEnabled;
          let isAppDriverValidityActivated =
            values.parent.isAppDriverValidityActivated;
          let isCompanyDriverValidityActivated =
            values.parent.isCompanyDriverValidityActivated;

          return !(
            boboEnabled &&
            isBOBOToggleEnabled &&
            isAppDriverValidityActivated &&
            isCompanyDriverValidityActivated
          );
        }
      ),
  });

  const onEnterResetPasswordDialog = () => {
    if (null !== btnYesRef.current) {
      btnYesRef.current.focus();
    }
  };

  const handleDeleteCompanyDialogOpen = () => {
    setOpen(true);
  };

  const handleDeleteCompanyDialogClose = () => {
    setOpen(false);
  };

  const handleDeleteClick = async () => {
    setOpen(false);
    setLoading(true);
    await axiosInstance
      .post("/company/deleteProfile?companyUid=" + companyProfile.companyUId)
      .then((response) => {
        appInsights.trackEvent({
          name: "Company Profile Deleted",
          properties: { CompanyID: companyProfile.companyId },
        });
        if (response.data.success) {
          setLoading(false);
          props.deleteAction(companyProfile.existingCompanyName);
        }
      })
      .catch((error) => {
        if (
          error.response != null &&
          error.response.status != null &&
          error.response.status === 400
        ) {
          dispatch(
            showErrorAlertMessage(
              t("Companies.DeleteCompanyProfileFailureMessage", {
                CompanyName: companyProfile.existingCompanyName,
              })
            )
          );
          dispatch(
            broadcastAnnouncement(
              t("Companies.DeleteCompanyProfileFailureMessage", {
                CompanyName: companyProfile.existingCompanyName,
              })
            )
          );
          console.error("There is an error", error);
        } else {
          console.error("There is an error", error);
          dispatch(showErrorAlertMessage(t("UnexpectedError")));
          dispatch(broadcastAnnouncement(t("UnexpectedError")));
        }
        appInsights.trackException({
          id: "Delete CompanyProfile",
          exception: error,
          severityLevel: SeverityLevel.Error,
        });
        setLoading(false);
      });
  };

  return (
    <Box id="profileBox">
      <Dialog
        aria-labelledby="deleteCompany-Dialog-title"
        open={open}
        role="dialog"
        TransitionProps={{
          onEnter: onEnterResetPasswordDialog
        }}>
        <DialogContent id="deleteCompany-Dialog-title">
          {t("Companies.DeleteCompanyConfirmationFormat", {
            CompanyName: companyProfile.existingCompanyName,
          })}
          <Divider
            style={{
              marginTop: 20,
              backgroundColor: "black",
              height: "0.8px",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            ref={btnYesRef}
            id="okDialogButton"
            onClick={() => handleDeleteClick()}
            style={{ textTransform: "none" }}
          >
            {t("Yes")}
          </Button>
          <Button
            classes={{ outlinedPrimary: classes.root }}
            color="primary"
            variant="outlined"
            type="button"
            id="cancelDialogButton"
            onClick={handleDeleteCompanyDialogClose}
            style={{ marginLeft: "0.5em", textTransform: "none" }}
          >
            {t("No")}
          </Button>
        </DialogActions>
      </Dialog>
      <Formik
        initialValues={loading ? InitialValue : companyProfile}
        validateOnChange={true}
        validateOnBlur={false}
        enableReinitialize={true}
        validationSchema={profileValidationSchema}
        onSubmit={async (values, { setFieldTouched, setSubmitting }) => {
          dispatch(clearAlertMessage());
          let modelToSetIsRentalMgmtLocked = {...values, isRentalMgmtLocked: true};
          await axiosInstance
            .post("/company/updateProfile", modelToSetIsRentalMgmtLocked)
            .then((response) => {
              setSubmitting(false);
              if (response.data.success) {
                appInsights.trackEvent({
                  name: "Company Profile Updated",
                  properties: { CompanyID: companyProfile.companyId },
                });
                dispatch(
                  showSuccessAlertMessage(
                    t("Companies.UpdateCompanyProfileSuccess")
                  )
                );
                dispatch(
                  broadcastAnnouncement(
                    t("Companies.UpdateCompanyProfileSuccess")
                  )
                );
                window.scrollTo(0, 0);
                props.action(response.data.companyUId);
              }
            })
            .catch((err) => {
              setSubmitting(false);
              if (err.response && err.response.data.messages) {
                const errorlist = err.response.data.messages.filter(
                  (n) => n !== ""
                );
                dispatch(showErrorAlertMessage(errorlist));
                dispatch(broadcastAnnouncement(errorlist.join(",")));
                if (err.response.data.messages.indexOf("company name")) {
                  if (companyNameRef.current !== null)
                    companyNameRef.current.focus();
                }
              } else {
                dispatch(
                  showErrorAlertMessage("Unable to update the company.")
                );
                dispatch(
                  broadcastAnnouncement("Unable to update the company.")
                );
              }
              appInsights.trackException({
                id: "Update CompanyProfile",
                exception: err,
                severityLevel: SeverityLevel.Error,
              });
            });
        }}
      >
        {({
          values,
          isValid,
          dirty,
          setFieldValue,
          setFieldTouched,
          touched,
          handleChange,
          errors,
          isSubmitting,
        }) => (
          <Grid container>
            <Backdrop
              className={classes.backdrop}
              open={isSubmitting || loading}
            >
              <CircularProgress />
            </Backdrop>
            <Grid item xs={12} md={6}>
              <div ref={titleRef} tabIndex={-1} className="visuallyHidden">
                {t("Companies.CompanyProfile")}
              </div>
              <Typography variant="h1" id="Title">
                {t("Companies.CompanyProfile")}
              </Typography>
            </Grid>

            <Grid container item xs={12} md={6} justifyContent="flex-end">
              {values.isDeleted ? (
                ""
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  id="btnDeleteCompany"
                  type="button"
                  onClick={() => handleDeleteCompanyDialogOpen()}
                  style={{ textTransform: "none" }}
                >
                  {t("Delete")}
                </Button>
              )}
            </Grid>

            <Grid
              container
              direction="row"
              style={{ marginTop: "1em" }}
              className={classes.root}
            >
              <Form style={{ width: "100%" }}>
                <Grid container>
                  <Grid
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                    item
                    xs={12}
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      {t("Companies.Company")}
                    </Typography>
                    <Field
                      inputRef={companyNameRef}
                      name="companyName"
                      id="compName"
                      fullWidth
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      inputProps={{
                        "aria-label": t("Companies.Company"),
                        "aria-required": true,
                        "data-validation": "client-validation-error",
                      }}
                      value={values.companyName}
                      onChange= {(e) => {
                        setFieldValue("companyName", e.target.value);
                        setFieldTouched("companyName");
                      }}
                      component={TextField}
                      variant="outlined"
                      placeholder={t("Companies.CompanyPlaceholder")}
                      error={isError(errors.companyName !== undefined)}
                      helperText={errors.companyName !== undefined  ? errors?.companyName : " "}
                    />
                  </Grid>
                  <Grid
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                    item
                    xs={12}
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      {t("Companies.ReasonForHireLabel")}
                    </Typography>
                    <Field
                      name="reasonForHireLabel"
                      id="rfhLabel"
                      fullWidth
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      inputProps={{
                        "aria-label": t("Companies.ReasonForHireLabel"),
                        "data-validation": "client-validation-error",
                      }}
                      value={values.reasonForHireLabel}
                      onChange= {(e) => {
                        setFieldValue("reasonForHireLabel", e.target.value);
                        setFieldTouched("reasonForHireLabel");
                      }}
                      component={TextField}
                      variant="outlined"
                      // placeholder={t("Companies.ReasonForHireLabelPlaceholder")}
                      error={isError(errors.reasonForHireLabel !== undefined)}
                      helperText={errors.reasonForHireLabel !== undefined  ? errors?.reasonForHireLabel : " "}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                  >
                    <FormControl id="countryLst" variant="outlined" fullWidth>
                      <Typography style={{ fontWeight: "bold" }}>
                        {t("Companies.Country")}
                      </Typography>
                      <Select
                        native
                        value={values.countries?.selectedValue}
                        onChange={(opt) => {
                          let text = values.countries?.items?.find(
                            (x) => x.value === opt.target.value
                          )?.text;
                          
                          if (props.isRentalMgmtLocked) {
                            if(isCountryInRentalManagementList(text))
                            {
                                if(props.isRentalMgmtEnabled){
                                  setFieldValue("isRentalMgmtEnabled", true);
                                }
                                else{
                                  setFieldValue("isRentalMgmtEnabled", false);
                                }
                            }
                            else{
                              setFieldValue("isRentalMgmtEnabled", false);
                            }
                          }
                          else if (isCountryInRentalManagementList(text)) {
                            setRmToggleDisabled(false);
                          }
                          else{
                            setRmToggleDisabled(true);
                            setFieldValue("isRentalMgmtEnabled", false);
                            }

                            setFieldValue("countries.selectedText", text);
                            setFieldValue(
                                "countries.selectedValue",
                                opt.target.value
                            );
                            setFieldTouched("countries");
                        }}
                        fullWidth
                        inputProps={{
                          id: "country.selectedValue",
                          name: "countries",
                          title: "select a country",
                          "aria-label": t("Companies.Country"),
                          "data-validation": "client-validation-error",
                        }}
                        name="countries"
                        error={isError(errors.countries !== undefined)}
                      >
                        {values.countries?.items?.map((opt: any) => (
                          <option key={opt.value} value={opt.value}>
                            {opt.text}
                          </option>
                        ))}
                      </Select>
                      {errors.countries && touched.countries ? (
                        <span className={classes.errorWarning}>
                          {errors.countries}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                  {values.isBOBOToggleEnabled && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 10,
                        marginBottom: "1em",
                      }}
                    >
                      <FormControl id="boboLst" variant="outlined" fullWidth>
                        <Typography style={{ fontWeight: "bold" }}>
                          {t("Companies.Bobo")}
                        </Typography>
                        <Select
                          native
                          value={values.boboEnabled ?? false}
                          onChange={(opt) => {
                            setFieldValue(
                              "boboEnabled",
                              opt.target.value === "true"
                            );
                            setFieldTouched("boboEnabled");
                          }}
                          fullWidth
                          inputProps={{
                            id: "bobo.selectedValue",
                            name: "boboEnabled",
                            title: "select Book On Behalf",
                            "aria-label": t("Companies.Bobo"),
                            "data-validation": "client-validation-error",
                          }}
                          name="boboEnabled"
                        >
                          {boboOptions.map((opt: any) => (
                            <option key={opt.id} value={opt.id}>
                              {opt.text}
                            </option>
                          ))}
                        </Select>
                        {errors.boboEnabled && touched.boboEnabled ? (
                          <span className={classes.errorWarning}>
                            {errors.boboEnabled}
                          </span>
                        ) : (
                          ""
                        )}
                      </FormControl>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                  >
                    <FormControl
                      id="rentalManagementLst"
                      variant="outlined"
                      fullWidth
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        {t("Companies.rentalManagement")}
                      </Typography>
                      <Select
                        native
                        value={values.isRentalMgmtEnabled ?? false}
                        onChange={(opt) => {
                          setFieldValue(
                            "isRentalMgmtEnabled",
                            opt.target.value === "true"
                          );
                          setFieldTouched("isRentalMgmtEnabled");
                        }}
                        fullWidth
                        inputProps={{
                          id: "rentalMgmtEnabled.selectedValue",
                          name: "isRentalMgmtEnabled",
                          title: "Rental Management",
                          "aria-label": t("Companies.rentalManagement"),
                          "data-validation": "client-validation-error",
                        }}
                        name="isRentalMgmtEnabled"
                        disabled={(values.isRentalMgmtLocked || isRmToggleDisabled)}
                      >
                        {boboOptions.map((opt: any) => (
                          <option key={opt.id} value={opt.id}>
                            {opt.text}
                          </option>
                        ))}
                      </Select>
                      {errors.isRentalMgmtEnabled &&
                      touched.isRentalMgmtEnabled ? (
                        <span className={classes.errorWarning}>
                          {errors.isRentalMgmtEnabled}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                  {values.isAllowAdminToAccessAllJourneysToggleEnabled && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 10,
                        marginBottom: "1em",
                      }}
                    >
                      <FormControl id="caaarLst" variant="outlined" fullWidth>
                        <Typography style={{ fontWeight: "bold" }}>
                          {t("Companies.AllowAdminToAccessAllJourneys")}
                        </Typography>
                        <Select
                          native
                          value={
                            values.isAllowAdminToAccessAllJourneys ?? false
                          }
                          onChange={(opt) => {
                            setFieldValue(
                              "isAllowAdminToAccessAllJourneys",
                              opt.target.value === "true"
                            );
                            setFieldTouched("isAllowAdminToAccessAllJourneys");
                          }}
                          fullWidth
                          inputProps={{
                            id: "isAllowAdminToAccessAllJourneys.selectedValue",
                            name: "isAllowAdminToAccessAllJourneys",
                            title: "Company Admins - Access All Reservations",
                            "aria-label": t(
                              "Companies.AllowAdminToAccessAllJourneys"
                            ),
                            "data-validation": "client-validation-error",
                          }}
                          name="isAllowAdminToAccessAllJourneys.selectedValue"
                        >
                          {boboOptions.map((opt: any) => (
                            <option key={opt.id} value={opt.id}>
                              {opt.text}
                            </option>
                          ))}
                        </Select>
                        {errors.isAllowAdminToAccessAllJourneys &&
                        touched.isAllowAdminToAccessAllJourneys ? (
                          <span className={classes.errorWarning}>
                            {errors.isAllowAdminToAccessAllJourneys}
                          </span>
                        ) : (
                          ""
                        )}
                      </FormControl>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingBottom: 0,
                      paddingTop: 10,
                      marginBottom: "1em",
                    }}
                  >
                    <FormControl id="mfaAuthLst" variant="outlined" fullWidth>
                      <Typography style={{ fontWeight: "bold" }}>
                        {t("Companies.mfaAuth")}
                      </Typography>
                      <Select
                        native
                        value={
                          values.isMfaEnabled ?? false
                        }
                        onChange={(opt) => {
                          setFieldValue(
                            "isMfaEnabled",
                            opt.target.value === "true"
                          );
                          setFieldTouched("isMfaEnabled");
                        }}
                        fullWidth
                        inputProps={{
                          id: "isMfaEnabled.selectedValue",
                          name: "isMfaEnabled",
                          title: "Multi-factor Authentication",
                          "aria-label": t(
                            "Companies.mfaAuth"
                          ),
                          "data-validation": "client-validation-error",
                        }}
                        name="isMfaEnabled.selectedValue"
                        disabled={(values.isSSOCompany)}
                      >
                        {boboOptions.map((opt: any) => (
                          <option key={opt.id} value={opt.id}>
                            {opt.text}
                          </option>
                        ))}
                      </Select>
                      {errors.isMfaEnabled && touched.isMfaEnabled ? (
                        <span className={classes.errorWarning}>
                          {errors.isMfaEnabled}
                        </span>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                  {values.isAppDriverValidityActivated && (
                    <Grid
                      style={{
                        paddingBottom: 0,
                        paddingTop: 10,
                        marginBottom: "1em",
                      }}
                      item
                      xs={12}
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        {t("Companies.DriverDeclaration")}
                      </Typography>
                      <Grid item xs={12} md={12}>
                        <label id="DriverDeclarationLabel">
                          <Field
                            type="checkbox"
                            name="isCompanyDriverValidityActivated"
                            checked={values.isCompanyDriverValidityActivated}
                            onChange= {(e) => {
                              setFieldValue("isCompanyDriverValidityActivated", e.target.checked);
                              setFieldTouched("isCompanyDriverValidityActivated");
                            }}
                            component={Checkbox}
                            id="DriverDeclarationCheckBox"
                            disabled={true}
                          />
                          {t("Companies.DriverDeclarationDescription")}
                        </label>
                      </Grid>
                    </Grid>
                  )}

                  <Grid container justifyContent="flex-end">
                    <Button
                      id="btnUpdate"
                      color="primary"
                      variant="contained"
                      disabled={!isValid || !dirty}
                      type="submit"
                      style={{ marginTop: "0.5em", textTransform: "none" }}
                    >
                      {t("Companies.btnSave")}
                    </Button>
                    <Button
                      classes={{ outlinedPrimary: classes.outlinedButton }}
                      id="btnCancel"
                      color="primary"
                      variant="outlined"
                      type="button"
                      onClick={() => {
                        props.action("");
                      }}
                      style={{
                        marginTop: "0.5em",
                        marginLeft: "0.5em",
                        textTransform: "none",
                      }}
                    >
                      {t("Companies.btnCancel")}
                    </Button>
                  </Grid>
                </Grid>
                <FocusError />
              </Form>
            </Grid>
          </Grid>
        )}
      </Formik>
    </Box>
  );
}
