import configParams from 'configParams';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();


const baseURL = window.location.hostname.endsWith("etd.enterprise.co.uk")
  ? configParams.API_BASE_URL
  : window.location.hostname.endsWith("etd.ehi.com")
  ? configParams.API_BASE_URL_EHI
  : (configParams.API_BASE_URL_Localhost ?? "http://localhost:3004/api/");

  export const getApiUrl = () :URL => {
    return new URL(baseURL);
  };

  export const getApiOrigin = () => {
    return getApiUrl().origin;
  };

  export const getApiProtocol = () => {
    return getApiUrl().protocol;
  };

  export const getApiPort = () => {
    return getApiUrl().port;
  };